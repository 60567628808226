import groupBy from 'lodash/groupBy';

import * as PRODUCT_ATTR_TYPE from '../constants/productAttributeType';
import grassdoorRedirects from '../constants/grassdoorRedirects';
import bentoRedirects from '../constants/bentoRedirects';
import { GRASSDOOR, BENTO } from '../constants/website';
import bentoCategoryHubDescription from '../constants/bentoCategoryHubDescription';

export default function converCategories(categories = [], hideCategoryDescriptions = false, isBestSeller = false, noProducts = false) {
  let groupCtegories = {};

  if (categories?.length) {
    const categoriesArr = groupBy(categories, item => item?.custom_category_name || `key_${item.id}`);

    Object.keys(categoriesArr).forEach(item => {
      categoriesArr[item].forEach((pItem, index) => {
        if (index === 0) {
          groupCtegories[item] = {
            category_name: pItem?.custom_category_name || pItem.category_name,
            full_shop_category_name: pItem.full_shop_category_name,
            category_slug: pItem.category_slug,
            id: pItem.id,
            category_description: !hideCategoryDescriptions ? pItem.category_description : '',
            originalPrice: pItem.product_price || 0,
            category_offer_description:
              !hideCategoryDescriptions && pItem.offer_category_id == pItem.id ? pItem.category_offer_description : '',
            category_sale_description:
              !hideCategoryDescriptions && pItem.category_sale_id == pItem.id ? pItem.category_sale_description : '',
            category_meta_title: pItem.category_meta_title || '',
            category_meta_description: pItem.category_meta_description || '',
            category_meta_tag: pItem.category_meta_tag || '',
            category_view_all: pItem.custom_view_all_link || pItem.category_view_all || '',
            category_image: pItem.logo_image_url || '',
            products: [],
            isMostPopular: isBestSeller
          };
        }

        !noProducts && groupCtegories[item].products.push(pItem);
      });
    });
  }

  groupCtegories = Object.values(groupCtegories);
  return groupCtegories;
}

export function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export function getCategoryUrl(slug) {
  let redirectionUrl = null;

  switch (process.env.NEXT_PUBLIC_APP) {
    case GRASSDOOR: {
      const { categoryRedirect } = grassdoorRedirects;
      redirectionUrl = categoryRedirect[slug];
      break;
    }

    case BENTO: {
      const { categoryRedirect } = bentoRedirects;
      redirectionUrl = categoryRedirect[slug];
      break;
    }

    default:
      break;
  }

  if ([GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) {
    if (redirectionUrl) {
      return redirectionUrl;
    }

    return `/categories/${slug}`;
  }

  return `/category/${slug}`;
}

export function reorderCategoryListing(categoryNames, list) {
  let updatedList = []
  categoryNames?.forEach(item => {
    let category = list?.find(cat => (item.toLowerCase()).includes(cat.full_shop_category_name.toLowerCase()))
    if (category) updatedList.push(category)
  }) || []
  return updatedList;
}

export function attachAndRemoveClass(id, className) {
  const element = document.getElementById(id);
  if (element) {
    element.classList.add(className);
    setTimeout(() => {
      element.classList.remove(className);
    }, 30000);
  }
}