import React, { useEffect, useRef, useContext, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Loader from './InlineLoader';

import { useModifyCart } from '../hooks/cart';
import appContext from '../Context/appContext';
import { ZIPPS } from '../constants/website';
import { useDeliveryDetails } from '../hooks/app';
import { FAVOURITE, SAVEFORLATER } from '../constants/wishlistConsts';

import notificationTypes from '../constants/modalNotificationConst';
import { useDispatch, useSelector } from 'react-redux';
import { setAddingToCart } from '../redux/slices/cartSlice';
import { pushNotificationOverlap } from '../redux/slices/modalSlice';

const ProductCounter = React.memo(({
  product,
  actionMade,
  dismiss,
  page,
  isASAP,
  type,
  className,
  scheduleType,
  isMostPopularProduct,
  compare,
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false,
  from,
  fromPage,
  isFeaturedProduct,
  addedFromCompare,
  filtersforSegment,
  showLoader,
  setShowLoader = () => { },
  setFirstTime = () => { },
  firstTime

}) => {
  const previousType = useRef();

  // const {
  //   state: {
  //     user: { },
  //     cart: { addToCartLoader, cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList }
  //   },
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const { amount } = useSelector(state => state.cart?.cartData) || {}
  const [quantity, setQuantity] = useState(false)
  const { product_details_id, bundle_id, product_id } = product
  const [itemInCart, setItemInCart] = useState({})
  const [otherQuanity, setOtherQuantity] = useState(true)
  const itemsInCart = useSelector(state => state.cart?.cartData?.cart_items)
  const { tempCart } = useSelector(state => state.cart)
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)
  // const itemInCart = itemsInCart ? itemsInCart.find(item => {
  //   const { product_details_id, bundle_id } = item
  //   return product_details_id === product_details_id || item.bundle_id === bundle_id
  // }) : { quantity: 0 }


  const quantityRef = useRef(0)

  useEffect(() => {
    let temp, tempTwo
    if (product.bundle_id) {
      temp = itemsInCart?.find(item => item.bundle_id == bundle_id)
      tempTwo = tempCart.find(item => item.product_id === product_id)

    } else {
      temp = itemsInCart?.find(item => item.product_details_id == product_details_id)
      tempTwo = tempCart.find(item => item.product_id === product_id)

    }

    if (tempTwo?.quantity) {
      quantityRef.current = tempTwo?.quantity
      setQuantity(tempTwo?.quantity)
    } else if (quantityRef.current === (temp?.quantity || 0) || (!tempTwo?.quantity && !temp?.quantity)) {
      quantityRef.current = null
    }

    (!showLoader) && (!quantityRef.current) && setQuantity(tempTwo?.quantity || temp?.quantity || 0)
    setItemInCart(temp)
  }, [itemsInCart, showLoader, tempCart])

  useEffect(() => {
    if (notificationsOverlap?.[0]?.type === 'confirmProductRemovalPopup') {
      setShowLoader && setShowLoader(false)
    }
  }, [notificationsOverlap])


  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const updatingProductsList = useSelector(state => state.cart?.updatingProductsList)

  const [updatingQuantity, setUpdatingQuantity] = useState(null)
  const { data: { isWalletCreditWithPurchaseApplicable } = {} } = useDeliveryDetails();
  useEffect(() => {
    setUpdatingQuantity(updatingProductsList.includes(product.bundle_id ? product.bundle_id : product.product_details_id))
  }, [updatingProductsList])
  // const updatingQuantity = updatingProductsList.includes(product.bundle_id ? product.bundle_id : product.product_details_id);

  // const { quantity = 0 } = itemInCart || {};

  const { updateCartItem, deleteCartItem, addToCart, validateAction } = useModifyCart({
    page,
    initialLocationAddress,
    initialFreightLimit,
    initialPurchaseLimit,
    initialUpsellProducts,
    initialDeliveryAddressDetails
  });

  const handleAddToCart = () => {
    addToCart({
      product,
      callback: error => {
        if (error) {
          dismiss();
        }
      },
      scheduleType,
      list: isMostPopularProduct ? `Top Sellers ${fromPage} ${page}` : page,
      type: from,
      isMostPopularProduct,
      fromPage,
      isFeaturedProduct,
      addedFromCompare,
      convertedFromWishlist: !!(from === FAVOURITE || from === SAVEFORLATER),
      filtersforSegment
    });
  };
  useEffect(() => {

    if (previousType.current === 'count' && type === 'counter') {
      if (quantity == 0) {
        handleAddToCart();
      } else {
        const error = validateAction({ product, scheduleType });
        if (error) {
          dismiss();
        }
      }
    } else if ((previousType.current === 'counter' || previousType.current == undefined) && type === 'counter' && (quantity === 0 || !quantity)) {
      handleAddToCart();
    }
    previousType.current = type;
  }, [type]);


  useEffect(() => {
    if (!updatingQuantity && !firstTime) {
      // dismiss();

      setShowLoader && setShowLoader(false)
    } else if (updatingQuantity) {
      setFirstTime(false)
    }
  }, [updatingQuantity]);

  async function onProductQuantityChange(e, changeType) {

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (actionMade) {
      actionMade();
    }

    if (isLoggedIn) {
      setShowLoader && setShowLoader(true)
    }
    else {
      setShowLoader && setShowLoader(true)
      let tO = setTimeout(() => {

        setShowLoader && setShowLoader(false)
        clearTimeout(tO)
      }, 900)
    }

    if (changeType === '-') {
      if (quantity == 1) {
        // delete item in cart
        setOtherQuantity(false)
        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: { ...product, quantity: 1 },
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: 0,
            onRemove: () => deleteCartItem({ product, isFeaturedProduct, list: page, type: from })
          };
        }

        deleteCartItem({ product, isFeaturedProduct, list: page, type: from, walletWithPurchaseProps });
      } else {

        const updatedOfferProd = itemInCart?.offer_product ? itemInCart?.offer_product : [];

        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: { ...product, quantity },
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: quantity - 1,
            onRemove: () =>
              updateCartItem({
                product: { ...product, quantity: quantity - 1, offer_product: updatedOfferProd },
                fromPage,
                isMostPopularProduct,
                isFeaturedProduct,
                addedFromCompare,
                convertedFromWishlist: !!(from === FAVOURITE || from === SAVEFORLATER)
              })
          };
        }

        updateCartItem({
          product: { ...product, quantity: quantity - 1, offer_product: updatedOfferProd },
          fromPage,
          isMostPopularProduct,
          isFeaturedProduct,
          addedFromCompare,
          walletWithPurchaseProps,
          convertedFromWishlist: !!(from === FAVOURITE || from === SAVEFORLATER)
        });
      }
    } else if (changeType === '+') {
      if (quantity === 0) {
        setQuantity(1)
        handleAddToCart();
      } else {
        const updatedOfferProd = itemInCart?.offer_product ? itemInCart?.offer_product : [];

        updateCartItem({
          product: { ...product, quantity: quantity + 1, offer_product: updatedOfferProd },
          fromPage,
          isMostPopularProduct,
          isFeaturedProduct,
          addedFromCompare
        });
      }
    }
  }
  const { is_sold_out: isSoldOut, product_max_quantity: maxQuantity } = product;

  let showCounter = true;
  if (isSoldOut || (!quantity && type === 'count')) {
    showCounter = false;
  }

  return (
    // <CSSTransition in={showCounter} mountOnEnter timeout={300} classNames="product-counter-anim" unmountOnExit>
    <>
      {compare && quantity < 1 ? (
        <button type="button" onClick={e => onProductQuantityChange(e, '+')} className="btn btn-primary product-counter-add-cart">
          <span>Add to cart</span>
        </button>
      ) : showCounter ? (
        <div
          role="presentation"
          onClick={e => {
            if (type === 'counter') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          className={` product-counter-parent p-${type} ${className}`}
        >
          <button
            type="button"
            disabled={showLoader || !quantity || updatingQuantity}
            onClick={e => onProductQuantityChange(e, '-')}
            className="btn"
          >
            <i className={` icon-${quantity < 2 ? `delete` : 'minus'} `} />
          </button>
          {(showLoader && type === 'counter') || (updatingQuantity && type === 'counter') || (!isLoggedIn && quantity == 0 && otherQuanity) ? (
            <Loader positionRelative className="px-3 auto-width a-loader loader-with-count" text={quantity} />
          ) : (
            <span className="count">{quantity} <span className="md:hidden sm:inline-block lg:hidden"> in Cart</span></span>
          )}
          <button
            type="button"
            disabled={showLoader || quantity >= maxQuantity || updatingQuantity}
            onClick={e => onProductQuantityChange(e, '+')}
            className="btn"
          >
            <i className="icon-plus" />
          </button>
        </div>
      ) : null}
    </>
    // </CSSTransition>
  );
});
ProductCounter.displayName = "ProductCounter"
export default ProductCounter;