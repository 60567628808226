import React from 'react';
import { withRouter } from 'next/router';

const ActiveLink = ({ children, router, className, href, onClick, otherAttributes = {} }) => {
  function handleClick(e) {
    e.preventDefault();
    if (typeof onClick === 'function') {
      onClick();
    }
    if (href) {
      router.push(href);
    }
  }

  return (
    <a href={href} className={className} onClick={handleClick} {...otherAttributes}>
      {children}
    </a>
  );
}

// No propTypes or defaultProps

export default withRouter(ActiveLink);
