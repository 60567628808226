import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { isRecommendedProductsByIdApplicable } from '../constants/feature';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import * as SCHEDULE_TYPE from '../constants/scheduleWindow';

import getDefaultTab from '../helpers/getDefaultTab';

import { useDeliveryDetails } from '../hooks/app';

import SimilarProductsList from './SimilarProductsList';
import DeliveryTabs from './DTC/DeliveryTabs';

const SimilarProducts = ({
  recommendedProducts = [],
  recommendedProductsByIdAsap = [],
  recommendedProductsByIdSchedule = [],
  className = '',
  openInPopup = false,
  modalRef = null,
  responseTab = '',
  currentProduct,
  onlySimilar,
  otherThanSimilar
}) => {
  const router = useRouter();

  const { data: { asapEnabled = true, scheduleEnabled = true, asapStartTime, asapEndTime, lastSlotCutOff } = {}, isLoading } =
    useDeliveryDetails();

  const {
    query: { tab = getDefaultTab({ asapStartTime, asapEndTime, lastSlotCutOff, asapEnabled }) }
  } = router;

  const asapArray = [];
  const scheduleArray = [];
  let asapRecommendedCount = 0;
  let scheduleRecommendedCount = 0;

  if (isRecommendedProductsByIdApplicable) {
    asapRecommendedCount = recommendedProducts?.asap_product_count;
    scheduleRecommendedCount = recommendedProducts?.schedule_product_count;
    if (recommendedProducts?.brandProducts?.asap_categories?.length) {
      asapArray.push({
        id: 1,
        title: `More from this Brand `,
        products: recommendedProducts.brandProducts.asap_categories
      });
    }

    if (recommendedProducts?.brandProducts?.schedule_categories?.length) {
      scheduleArray.push({
        id: 1,
        title: `More from this Brand `,
        products: recommendedProducts.brandProducts.schedule_categories
      });
    }

    if (recommendedProducts?.similarProducts?.asap_categories?.length) {
      asapArray.push({
        id: 2,
        title: 'Similar Products',
        products: recommendedProducts.similarProducts.asap_categories
      });
    }

    if (recommendedProducts?.similarProducts?.schedule_categories?.length) {
      scheduleArray.push({
        id: 2,
        title: 'Similar Products',
        products: recommendedProducts.similarProducts.schedule_categories
      });
    }
  }

  if (recommendedProductsByIdAsap && isRecommendedProductsByIdApplicable) {
    asapRecommendedCount = recommendedProductsByIdAsap?.asap_product_count;
    if (asapRecommendedCount) {
      Object.keys(recommendedProductsByIdAsap).forEach((keys, i) => {
        if (keys !== 'asap_product_count') {
          asapArray.push({
            id: i + 1,
            title: `${recommendedProductsByIdAsap[keys]?.label}`,
            products: recommendedProductsByIdAsap[keys]?.asap_categories?.length
              ? recommendedProductsByIdAsap[keys].asap_categories
              : []
          });
        }
      });
    }
  }

  if (recommendedProductsByIdSchedule && isRecommendedProductsByIdApplicable) {
    scheduleRecommendedCount = recommendedProductsByIdSchedule?.schedule_product_count;
    if (scheduleRecommendedCount) {
      Object.keys(recommendedProductsByIdSchedule).forEach((keys, i) => {
        if (keys !== 'schedule_product_count') {
          scheduleArray.push({
            id: i + 1,
            title: `${recommendedProductsByIdSchedule[keys]?.label}`,
            products: recommendedProductsByIdSchedule[keys]?.schedule_categories?.length
              ? recommendedProductsByIdSchedule[keys].schedule_categories
              : []
          });
        }
      });
    }
  }

  const [currentTab, setTab] = useState(responseTab || tab);

  useEffect(() => {
    if (responseTab) {
      setTab(responseTab);
    }
  }, [responseTab]);

  useEffect(() => {
    if (!isLoading) {
      if (!scheduleEnabled) {
        setTab(ASAP);
      } else if (!asapEnabled) {
        setTab(SCHEDULE);
      }
    }
  }, [isLoading, asapEnabled, scheduleEnabled]);

  if (recommendedProducts || recommendedProductsByIdAsap || recommendedProductsByIdSchedule) {
    return (
      <div className={!asapArray?.length && !scheduleArray?.length ? 'd-none ' : `${className} product-popup-flex `}>
        {!onlySimilar ? (
          <DeliveryTabs
            asapProductsCount={asapRecommendedCount || 0}
            scheduledProductsCount={scheduleRecommendedCount || 0}
            asapEnabled={asapEnabled}
            scheduleEnabled={scheduleEnabled}
            currentTab={currentTab}
            setTab={setTab}
            hasAsapProducts={!!asapArray?.length}
            hasScheduleProducts={!!scheduleArray?.length}
            forSimilarProduct
            openInPopup={openInPopup}
            from="similarProduct"
          />
        ) : null}

        {currentTab == ASAP ? (
          <SimilarProductsList
            scheduleType={SCHEDULE_TYPE.ASAP}
            products={asapArray}
            loading={false}
            openInPopup={openInPopup}
            modalRef={modalRef}
            tab={currentTab}
            currentProduct={currentProduct}
            onlySimilar={onlySimilar}
            otherThanSimilar={otherThanSimilar}
          />
        ) : (
          <SimilarProductsList
            scheduleType={SCHEDULE_TYPE.Scheduled}
            products={scheduleArray}
            loading={false}
            openInPopup={openInPopup}
            modalRef={modalRef}
            tab={currentTab}
            currentProduct={currentProduct}
            onlySimilar={onlySimilar}
            otherThanSimilar={otherThanSimilar}
          />
        )}
      </div>
    );
  }

  return null;
};

export default SimilarProducts;
