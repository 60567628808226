
import Axios from "axios";
import { wishlistToast } from "../helpers/wishlistToast";
import storageService from "../services/storageService";
import axiosInstance from "../utils/ajax";
import useSWR from "swr";
import { useEffect, useState } from "react";


const fetcher = (url) => fetch(url).then((res) => res.json());

export async function addToFavouritesAPI({
  items = [],
  hideSuccess: hideOnSuccess = false,
  hideError = false,
  updateIsFavouriteItemAddedOrRemoved = true,
  isMostPopularProduct = false,
  isFeaturedProduct = false,
  callSegmentEvent = true,
  type = 'home',
  fromPage = '',
  isLoggedIn
} = {}) {
  try {
    if (isLoggedIn) {
      // API call
      const requestData = {
        product_id: [],
        bundle_id: []
      };

      items.forEach(item => {
        if (item.bundle_id) {
          requestData.bundle_id.push(item.bundle_id);
        } else if (item.product_details_id) {
          requestData.product_id.push(item.product_details_id);
        }
      });

      if (!requestData?.bundle_id.length) delete requestData.bundle_id;
      if (!requestData?.product_id.length) delete requestData.product_id;

      if (requestData?.bundle_id || requestData?.product_id) {
        const response = await axiosInstance({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/favourite/product`,
          method: 'POST',
          data: requestData,
          hideSuccess: true,
          hideError
        });

        if (!hideOnSuccess && response.data.success) {
          wishlistToast(`Product Successfully added to your Favorites`);
        }
      }
    } else {
      items.map(item => storageService.addToFavourites(item));
      if (!hideOnSuccess) {
        wishlistToast(`Product Successfully added to your Favorites`);
      }
    }

    if (updateIsFavouriteItemAddedOrRemoved) {
      // dispatch({ type: 'setIsFavouriteItemAddedOrRemoved', payload: true });
    }

    // update allFavouriteItemIds in context
    // if (isLoggedIn) {
    //   dispatch({ type: 'setAllFavouriteItemIds', payload: [...allFavouriteItemIds, items[0]?.product_details_id] });
    // }

    return true;
  } catch (error) {
    console.error('API.js_addToFavourites', error);
    return false;
  } finally {
    const product = items[0];
    if (product && callSegmentEvent) {
      // trackEvent('Product added favorite', {
      //   recommendation_type: product.recommendation_type,
      //   product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
      //   sku: product.bundle_id ? product.bundle_id : product.master_product_id,
      //   best_seller: isMostPopularProduct,
      //   best_seller_type: fromPage,
      //   type,
      //   is_featured_product: isFeaturedProduct,
      //   category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
      //   category: product.full_shop_category_name || '',
      //   name: product.name || product.product_name,
      //   product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
      //   brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
      //   variant: `${product.category_weight} ${product.category_unit}`,
      //   price: product.product_price || product.price,
      //   original_price: product.price_without_deal || product.price,
      //   strain_type: product.product_strain_type_name || '',
      //   position: 1,
      //   url: `${origin}/product/${product.slug}`,
      //   image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
      // });
    }
  }
}
export async function removeFromFavouritesAPI({
  item = {},
  isMostPopularProduct = false,
  isFeaturedProduct = false,
  type = 'home',
  fromPage = '',
  isLoggedIn
}) {
  try {
    if (isLoggedIn) {
      // API call
      let productType;
      let id;

      if (item?.bundle_id) {
        productType = 'bundle';
        id = item.bundle_id;
      } else if (item?.product_details_id) {
        productType = 'product';
        id = item.product_details_id;
      }
      const response = await axiosInstance({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/favourite/${productType}/${id}`,
        method: 'DELETE',
        hideSuccess: true,
        hideError: true
      });

      if (response.data.success) {
        wishlistToast(`Product Removed from your Favorites`);
      }
    } else {
      storageService.deleteFavouriteProduct(item);
      wishlistToast(`Product Removed from your Favorites`);
    }

    // dispatch({ type: 'setIsFavouriteItemAddedOrRemoved', payload: true });
    // dispatchRedux(setIsFavouriteItemAddedOrRemoved(true))
    // update allFavouriteItemIds in context
    // if (isLoggedIn) {
    //   dispatch({ type: 'setAllFavouriteItemIds', payload: allFavouriteItemIds.filter(id => id != item?.product_details_id) });
    // }

    return true;
  } catch (error) {
    console.error('API.js_removeFromFavourites', error);
    return false;
  } finally {
    const product = { ...item };
    // if (product) {
    //   trackEvent('Favorites Product removed', {
    //     recommendation_type: product.recommendation_type,
    //     product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
    //     sku: product.bundle_id ? product.bundle_id : product.master_product_id,
    //     best_seller: isMostPopularProduct,
    //     best_seller_type: fromPage,
    //     type,
    //     is_featured_product: isFeaturedProduct,
    //     category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
    //     category: product.full_shop_category_name || '',
    //     name: product.name || product.product_name,
    //     product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
    //     brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
    //     variant: `${product.category_weight} ${product.category_unit}`,
    //     price: product.product_price || product.price,
    //     original_price: product.price_without_deal || product.price,
    //     strain_type: product.product_strain_type_name || '',
    //     position: 1,
    //     url: `${origin}/product/${product.slug}`,
    //     image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
    //   });
    // }
  }
}

export async function removeFromSaveForLaterAPI({
  item = {},
  type = 'home',
  isMostPopularProduct = false,
  fromPage = '',
  isFeaturedProduct = false,
  isLoggedIn
}) {
  // dispatch({ type: 'setSaveForLaterItemsLoading', payload: true });
  try {
    if (isLoggedIn) {
      // API call
      let productType;
      let id;

      if (item?.bundle_id) {
        productType = 'bundle';
        id = item.bundle_id;
      } else if (item?.product_details_id) {
        productType = 'product';
        id = item.product_details_id;
      }

      const response = await axiosInstance({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/saved_for_later/${productType}/${id}`,
        method: 'DELETE',
        hideSuccess: true,
        hideError: true
      });
      if (response.data.success) {
        wishlistToast(`Product Removed from Saved For Later list`);
      }
    } else {
      storageService.deleteSaveForLaterProduct(item);
      wishlistToast(`Product Removed from Saved For Later list`);
    }
    return true
    // dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: true });
  } catch (error) {
    console.error('API.js_removeFromSaveForLater', error);
    return false
  }
}

export async function fetchProductListInGrid(page) {
  try {
    // https://demo-api.grassdoorpos.com/v1/product/list/schedule/view/grid/
    const response = await axiosInstance({
      url: `https://demo-api.grassdoorpos.com/v1/product/list/schedule/view/grid/price/asc/${page}`,
      method: 'GET',
      hideSuccess: true,
      hideError: false
    })
    return response.data
  } catch (error) {
    throw new error
  }
}

export const useGridProductList = ({ initialList, revalidate = false, page }) => {
  const options = { revalidateOnMount: revalidate };
  const [productList, setProductList] = useState(initialList || [])

  const { data, error, mutate } = useSWR(
    `https://demo-api.grassdoorpos.com/v1/product/list/schedule/view/grid/price/asc/${page}`,
    fetcher,
    options
  );
  useEffect(() => {
    if (!data?.data) return
    let temp = (data?.data?.products || [])
    setProductList(page > 1 ? [...productList, ...temp] : temp)

  }, [data])
  return {
    data: productList,
    isLoadingProducts: !error && !data,
  }

}