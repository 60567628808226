import React, { useContext, useEffect } from 'react';
import Cookies from 'cookies';
import get from 'lodash/get';
import Head from 'next/head';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import axios from '../../utils/ajax';
import Header from '../../components/Header';
import applyDiscount from '../../helpers/applyDiscount';
import Page404 from '../404';

import oldProductSlug from '../../constants/oldProductSlug';
import * as WEBSITES from '../../constants/website';

import newProductSlug from '../../constants/newProductSlug';

import * as COOKIE_VARIABLES from '../../constants/cookieVariables';
import { DEFAULT_LOCATION, DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../../constants/default';

import { useProductDetails } from '../../hooks/Shop';

// import appConfig from '../../appConfig';
import StorageService from '../../services/storageService';

import ProductContainer from '../../components/ProductBundleContainer';
import useSegment from '../../hooks/segment';
import appContext from '../../Context/appContext';
import getDefaultTab from '../../helpers/getDefaultTab';
import { SCHEDULE, ASAP } from '../../constants/deliveryType';
import grassdoorRedirects from '../../constants/grassdoorRedirects';
import pabstlabsRedirect from '../../constants/pabstlabsRedirect';
import kurvanaRedirects from '../../constants/kurvanaRedirects';

import getHost from '../../helpers/gethost';
import pageDisable from '../../helpers/pageDisableHelper';
import { useDeliveryDetails } from '../../hooks/app';
import { isNewHomePageApplicable } from '../../constants/feature';
import SearchInputBoxWrapper from '../../components/SearchInputBoxWrapper';
import { popNotification, popStack } from '../../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { withConfig } from '../../lib/withConfig';
import { useConfigData } from '../../Context/ConfigProvider';

const canonicalHref = `https://${getHost()}/product/`; //same used for og:url

const ProductPage = ({
  productDetails: initialProductDetails,
  host,
  error: overrideError,
  slug: overrideSlug,
  showTag,
  title,
  tab: overrideTab,
  showNavbar = true,
  openInPopup,
  modalRef,
  dismiss,
  id,
  isMostPopularProduct,
  isFeaturedProduct,
  widgetTypeAdded,
  widgetType
}) => {
  const appConfig = useConfigData();
  const router = useRouter();
  const { data: { asapStartTime, asapEndTime, lastSlotCutOff, asapEnabled } = {} } = useDeliveryDetails();
  const {
    query: { slug: querySlug, tab: queryTab = getDefaultTab({ asapStartTime, asapEndTime, lastSlotCutOff, asapEnabled }) }
  } = router;

  const tab = overrideTab || queryTab;
  const slug = overrideSlug || querySlug;

  const {
    data: { productDetails },
    isLoading,
    isError: queryError,
    isValidating,
  } = useProductDetails({
    slug,
    tab,
    initialProductDetails
  });

  const error = overrideError || queryError;
  const { trackEvent, trackPage } = useSegment();

  // const {
  //   state: {
  //     user: { couponData },
  //   },
  //   dispatch
  // } = useContext(appContext);
  const couponData = useSelector(state => state.user.couponData)
  const stackURL = useSelector(state => state.modal.stackURL)

  const dispatchRedux = useDispatch()

  const { discount_code: discountCode } = couponData;

  const segmentProductId = productDetails?.master_product_id || null;

  function setTab(newTab) {
    if (tab !== newTab) {
      const href = `/?tab=${newTab}`;
      router.replace(href, undefined, { shallow: true });
    }
  }

  useEffect(() => {
    if (process.browser && !error && segmentProductId) {
      const origin = window.location.origin;

      let newDiscountedPrice = productDetails.price;
      if (productDetails.orignalPrice && productDetails.orignalPrice !== productDetails.price) {
        newDiscountedPrice = productDetails.price;
      } else if (Array.isArray(couponData.product_ids) && couponData.product_ids.length) {
        if (couponData.product_ids.findIndex(o => parseInt(o, 10) === parseInt(productDetails.product_id, 10)) > -1) {
          newDiscountedPrice = applyDiscount(productDetails, productDetails.price, couponData);
        }
      } else {
        newDiscountedPrice = applyDiscount(productDetails, productDetails.price, couponData);
      }

      const zoneName = StorageService.getZoneLocalStorage();

      trackPage('Product Details Page', {
        url: `${origin}/product/${slug}`,
        path: `/product/${slug}`
      });

      trackEvent('Product Viewed', {
        brand: productDetails?.brands?.[0]?.name || '',
        product_attributes: Array.isArray(productDetails.product_attributes_second)
          ? productDetails.product_attributes_second.join('/')
          : '',
        coupon: discountCode || '',
        category_frontend: productDetails.category_name,
        category: productDetails.full_shop_category_name,
        currency: 'usd',
        name: productDetails.product_name || productDetails.name,
        original_price: productDetails.price_without_deal || productDetails.price,
        position: '1',
        price: newDiscountedPrice || productDetails.product_price || productDetails.price,
        product_id: segmentProductId,
        quantity: 1,
        sku: segmentProductId,
        strain_type: productDetails.product_strain_type_name || '',
        variant: `${productDetails.product_weight} ${productDetails.product_unit}`,
        url: `${origin}/product/${slug}`,
        path: `/product/${slug}`,
        zone_name: zoneName,
        best_seller: isMostPopularProduct,
        is_featured_product: isFeaturedProduct,
        widget_category_type: widgetTypeAdded,
        widget_type: widgetType
      });
    }
  }, [segmentProductId]);

  if (error === 404) {
    return (
      <>
        <Page404 showNavbar={showNavbar} openInPopup={openInPopup} />
      </>
    );
  }
  if ((!isLoading && !isValidating && isEmpty(productDetails)) || (!isLoading && isEmpty(productDetails))) {
    return (
      <>
        <Page404 openInPopup={openInPopup} showNavbar={showNavbar} titleMessage="Product not found" subMesaage="" />
      </>
    );
  }

  function renderProductSchema() {
    const largeImage = productDetails.images && productDetails.images.length && productDetails.images[0];

    const mediumImage =
      productDetails.thumb_medium_images && productDetails.thumb_medium_images.length && productDetails.thumb_medium_images[0];

    const smallImage =
      productDetails.thumb_small_images &&
      productDetails.thumb_small_images.length &&
      productDetails.thumb_small_images.length[0];

    let images = [smallImage, mediumImage, largeImage];
    images = images.filter(image => image);

    let productProperty = null;

    switch (productDetails.product_unit) {
      case 'kg':
        productProperty = { unitCode: 'GRM', value: Number(productDetails.product_weight) * 1000 };
        break;

      case 'g':
      case 'gram':
      case 'grams':
        productProperty = { unitCode: 'GRM', value: Number(productDetails.product_weight) };
        break;

      case 'lb':
        productProperty = { unitCode: 'GRM', value: Number(productDetails.product_weight) * 453.592 };
        break;

      case 'oz':
        productProperty = { unitCode: 'ONZ', value: Number(productDetails.product_weight) };
        break;

      case '1/8 oz':
        productProperty = { unitCode: 'ONZ', value: 0.125 };
        break;

      case '1/2 oz':
        productProperty = { unitCode: 'ONZ', value: 0.5 };
        break;

      case 'mg':
        productProperty = { unitCode: 'MGM', value: Number(productDetails.product_weight) };
        break;

      case 'EA':
      default:
        productProperty = null;
        break;
    }

    const schemaObject = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productDetails.product_name,
      image: images,
      description: productDetails.product_description,
      sku: String(productDetails.product_details_id),
      mpn: String(productDetails.product_id),
      offers: {
        '@type': 'Offer',
        url: `${host}/product/${productDetails.slug}`,
        price: productDetails.price,
        priceCurrency: 'USD',
        availability: productDetails.is_sold_out ? 'OutOfStock' : 'InStock',
        priceValidUntil: '2021-11-20'
      }
    };

    const brand = productDetails?.brands?.[0];
    if (brand) {
      schemaObject.brand = { '@type': 'Brand', name: brand, '@id': `${host}/brands/${brand.name}` };
    }

    if (productProperty) {
      schemaObject.offers.additionalProperty = {
        '@type': 'PropertyValue',
        name: 'Weight',
        ...productProperty
      };
    }

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaObject) }} />;
  }

  function openBrandPage(e) {
    e.preventDefault();
    dispatchRedux(popNotification())

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < stackURL; i++) {
      window.history.back();
    }
    dispatchRedux(popStack())
    // eslint-disable-next-line no-restricted-globals
    location.href = `/brands/${productDetails.brands[0]}`;
  }

  return (
    <>
      <Head>
        {renderProductSchema()}
        <title>{productDetails && productDetails.category_name ? `${productDetails.meta_title}` : appConfig.APP_NAME}</title>
        <meta name="description" content={productDetails ? productDetails.meta_description : appConfig.APP_NAME} />
        <meta property="og:title" content={productDetails ? productDetails.og_title : appConfig.APP_NAME} />
        <meta property="og:description" content={productDetails ? productDetails.og_description : appConfig.APP_NAME} />
        <meta property="og:image" content={productDetails ? productDetails.og_image : appConfig.META_IMAGE} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${canonicalHref}${slug}`} />
        <link rel="canonical" href={`${canonicalHref}${slug}`} />
      </Head>
      {showNavbar && (
        <Header
          hasText
          backPath
          toPath="/"
          redirectToHome
          // pageName={process.env.NEXT_PUBLIC_APP === WEBSITES.LOWELL ? 'Product' : null}
          // hasShadow
          hasWhiteBg
          hideTabs
          showFilter
          showUserInfo={isNewHomePageApplicable}
          showProfile
          showDeliverySpecificMessage={false}
          showLogin={!isNewHomePageApplicable}
          setTab={setTab}
          help={isNewHomePageApplicable}
        />
      )}
      <ProductContainer
        loading={isLoading}
        updating={isValidating}
        slug={slug}
        showTag={showTag}
        title={title}
        page={tab === SCHEDULE ? 'Schedule List' : 'ASAP List'}
        openInPopup={openInPopup}
        openBrandPage={openBrandPage}
        modalRef={modalRef}
        productDetails={{ ...productDetails, is_favourite: productDetails?.is_favourite }}
        dismiss={dismiss}
        tab={tab}
        id={productDetails.master_product_id}
        isMostPopularProduct={isMostPopularProduct}
        isFeaturedProduct={isFeaturedProduct}
        showNavbar={showNavbar}
        grassdoorProduct
      />
    </>
  );
};

const { productRedirects: redirects } = grassdoorRedirects;
const { productRedirects: pabstlabsproductRedirects } = pabstlabsRedirect;
const { productRedirects: kurvanaProductRedirects } = kurvanaRedirects;

async function getServerSidePropsFunction({ req, res, query: { slug, tab } }) {
  if (tab !== 'asap' && tab !== 'schedule' && tab !== undefined) {
    return {
      redirect: {
        destination: `/product/${slug}?tab=asap`,
        permanent: false
      }
    };
  }
  const index = oldProductSlug.indexOf(slug);
  if (slug == 'gift-card') {
    res.writeHead(301, { location: '/gift-cards' });
    res.end();
  }
  if (process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && slug === 'apple-cider-diamonds-3802') {
    res.writeHead(302, { location: `/` });
    res.end();
  } else if (redirects[slug] && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR) {
    res.writeHead(301, { location: `${redirects[slug]}` });
    res.end();
  } else if (process.env.NEXT_PUBLIC_APP === WEBSITES.KIKOKO) {
    const kikokoRedirect = {
      'herbal-tea': '/shop/herbal-tea'
    };
    if (kikokoRedirect[slug]) {
      res.writeHead(301, { location: `${kikokoRedirect[slug]}` });
      res.end();
    }
  } else if (process.env.NEXT_PUBLIC_APP === WEBSITES.PABSTLABS) {
    if (pabstlabsproductRedirects[slug]) {
      res.writeHead(301, { location: `/product${pabstlabsproductRedirects[slug]}` });
      res.end();
    }
  } else if (process.env.NEXT_PUBLIC_APP === WEBSITES.KURVANA) {
    if (kurvanaProductRedirects[slug]) {
      res.writeHead(301, { location: `${kurvanaProductRedirects[slug]}` });
      res.end();
    }
  } else if (slug && index !== -1) {
    res.writeHead(301, { location: `/product/${newProductSlug[index]}` });
    res.end();
  }

  if (process.env.NEXT_PUBLIC_APP === WEBSITES.CLAYBOURNE) {
    const claybourneUpdatedUrls = {
      'durban-poison-el-bluntitos': 'durban-poison-el-bluntito',
      'strawberry-c-r-e-a-m-blunt': 'strawberry-cream-blunt',
      'strawberry-c-r-e-a-m': 'strawberry-cream'
    };

    if (claybourneUpdatedUrls[slug]) {
      res.writeHead(301, { location: `/product/${claybourneUpdatedUrls[slug]}` });
      res.end();
    }
  }

  const cookies = new Cookies(req, res);
  const zipcode = cookies.get(COOKIE_VARIABLES.zipcode) || DEFAULT_ZIPCODE;
  const latitude = cookies.get(COOKIE_VARIABLES.latitude) || DEFAULT_LOCATION.lat;
  const longitude = cookies.get(COOKIE_VARIABLES.longitude) || DEFAULT_LOCATION.lng;
  const placeId = cookies.get(COOKIE_VARIABLES.placeId) || '';
  const host = get(req, 'headers.host', '');
  const token = cookies.get(COOKIE_VARIABLES.token) || '';
  const zoneId = cookies.get(COOKIE_VARIABLES.zoneId) || DEFAULT_ZONE_ID;

  let authorizationToken = {};
  if (token) {
    authorizationToken = {
      authorization: `Bearer ${token}`
    };
  }

  try {
    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/${slug}/${zipcode}`,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, ...authorizationToken }
    });
    const productDetails = get(response, 'data.categories', {});
    if (Object.keys(productDetails).length === 0 && productDetails.constructor === Object) {
      return { props: { error: 404, id: String(slug), key: String(slug) } };
    }
    return { props: { productDetails, host, id: String(slug), key: String(slug) } };
  } catch (error) {
    res.statusCode = 404;
    return { props: { error: 404, id: String(slug), key: String(slug) } };
  }
}
export const getServerSideProps = withConfig(getServerSidePropsFunction)
export default pageDisable(ProductPage);
