import React, { useState } from 'react';

const OtpInputBox = ({ length, onChange }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) return; // Only allow numbers

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Pass the entire OTP string as the event.target.value
        const otpValue = newOtp.join('');
        e.target.value = otpValue;
        onChange(e);

        // Focus next input
        if (value && index < length - 1) {
            const nextInput = document.getElementById(`otp-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    return (
        <div className="flex space-x-2">
            {otp.map((_, index) => (
                <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="w-10 h-10 text-center text-2xl font-medium border border-gray-300 rounded focus:outline-none"
                />
            ))}
        </div>
    );
};

export default OtpInputBox;
