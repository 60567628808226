import React from 'react';

const LottieRender = ({ path, className, config = {}, speed = 1 }) => {
  const ref = React.useRef();
  const [lottie, setLottie] = React.useState(null);

  React.useEffect(() => {
    import('lottie-web').then(Lottie => setLottie(Lottie.default));
  }, []);

  React.useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: config.renderer || 'svg',
        loop: config.loop !== undefined ? config.loop : true,
        autoplay: config.autoplay !== undefined ? config.autoplay : true,
        path: path,
        ...config,  // Include any additional configuration
      });

      // Set the speed based on the `speed` prop
      animation.setSpeed(speed);

      return () => animation.destroy();
    }
  }, [lottie, config, path, speed]);

  return <div ref={ref} className={className}></div>;
};

export default LottieRender;
