import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './slices/cartSlice'
import userReducer from './slices/userSlice'
import wishlistSlice from './slices/wishlistSlice'
import modalSlice from './slices/modalSlice'
import otherSlice from './slices/otherSlice'
import compareSlice from './slices/compareSlice'
import ratingSlice from './slices/ratingSlice'
import searchSlice from './slices/searchSlice'
import rewardSlice from './slices/rewardSlice'
import sidebarSlice from './slices/sidebar'
export default configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    wishlist: wishlistSlice,
    modal: modalSlice,
    other: otherSlice,
    compare: compareSlice,
    rating: ratingSlice,
    search: searchSlice,
    rewards: rewardSlice,
    sidebar: sidebarSlice
  }
})