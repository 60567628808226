import React, { useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { isNewHomePageApplicable, isRecommendedProductsByIdApplicable } from '../constants/feature';
import * as WEBSITES from '../constants/website';

import useAPI from '../NetworkCall/API';

import CannadipsFooter from './DTC/cannadipsthc/CannadipsFooter';
import WunderFooter from './DTC/wunder/WunderFooter';

import ImageGridCarousel from './ImageGridCarousel';
import SimilarProducts from './SimilarProducts';
import ProductContent from './ProductContent';
import InlineLoader from './InlineLoader';
// import appConfig from '../appConfig';
import getStrainDetails from '../helpers/getStrainDetails';
import SearchInputBoxWrapper from './SearchInputBoxWrapper';
import SearchButton from './searchModule/SearchButton';
import appContext from '../Context/appContext';
import { useConfigData } from '../Context/ConfigProvider';

const ProductBundleContainer = ({
  title,
  loading,
  updating,
  productDetails,
  slug,
  showTag,
  page,
  openInPopup,
  openBrandPage,
  modalRef,
  grassdoorProduct = false,
  DTCproduct = false,
  grassdoorBundle = false,
  DTCBundle = false,
  tab,
  id,
  isMostPopularProduct,
  isFeaturedProduct,
  showNavbar
}) => {
  const {
    state: { productRecommendationsAsap, productRecommendationsSchedule }
  } = useContext(appContext);
  const [recommendedProducts, setRecommendedProducts] = useState({});
  const [hideLoader, setHideLoader] = useState(false);
  const [viewTab, setViewTab] = useState(null);
  const [recommendedProductsByIdAsap, setRecommendedProductsByIdAsap] = useState(
    productRecommendationsAsap.slug === slug ? productRecommendationsAsap : null
  );
  const [recommendedProductsByIdSchedule, setRecommendedProductsByIdSchedule] = useState(
    productRecommendationsSchedule.slug === slug ? productRecommendationsSchedule : null
  );
  const appConfig = useConfigData();
  const productPresent = !productDetails.is_sold_out
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_APP === WEBSITES.DRINKCANN ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.KOAN ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.WUNDER ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.CANNADIPSTHC ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.BEED ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.POTLI ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.ALTLIFE ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.HELLOAGAIN ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.VETCBD ||
      // process.env.NEXT_PUBLIC_APP === WEBSITES.HUMBOLDTFAMILYFARMS ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.MAISONBLOOM ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.BOASTCANNABIS ||
      process.env.NEXT_PUBLIC_APP === WEBSITES.RIGHTRATIO
    ) {
      if (DTCproduct || DTCBundle) {
        document.body.classList.add('drinkcann-product-page');
      }
      if (DTCproduct) {
        document.body.style.setProperty('--pfg', productDetails.theme.foregroundColor);
        document.body.style.setProperty('--pbg', productDetails.theme.backgroundColor);
      }
    }
    if ((DTCBundle && process.env.NEXT_PUBLIC_APP === WEBSITES.MAVENCANNABISCLUB) || DTCproduct) {
      document.body.classList.add('isproductpage');
    }
    // returned function will be called on component unmount
    return () => {
      if (
        process.env.NEXT_PUBLIC_APP === WEBSITES.DRINKCANN ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.KOAN ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.WUNDER ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.CANNADIPSTHC ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.BEED ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.POTLI ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.ALTLIFE ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.HELLOAGAIN ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.VETCBD ||
        // process.env.NEXT_PUBLIC_APP === WEBSITES.HUMBOLDTFAMILYFARMS ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.MAISONBLOOM ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.BOASTCANNABIS ||
        process.env.NEXT_PUBLIC_APP === WEBSITES.RIGHTRATIO
      ) {
        document.body.classList.remove('drinkcann-product-page');
        // document.body.style.removeProperty('--pfg');
        // document.body.style.removeProperty('--pbg');
      }
      if ((DTCBundle && process.env.NEXT_PUBLIC_APP === WEBSITES.MAVENCANNABISCLUB) || DTCproduct) {
        document.body.classList.remove('isproductpage');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRecommendedProductsBySlug, getRecommendedProductsByIdASAP, getRecommendedProductsByIdSchedule } = useAPI();

  useEffect(() => {
    if ((grassdoorProduct || grassdoorBundle || (DTCproduct && process.env.NEXT_PUBLIC_DEFAULT_SITES)) && !DTCBundle) {
      if (isRecommendedProductsByIdApplicable) {
        // updateGDRecommendedProducts();
        updateRecommendedProducts();
      } else
        if (!grassdoorBundle) {
          updateRecommendedProducts();
        }
    }
  }, [slug]);

  // let viewTab = 'asap';

  const updateGDRecommendedProducts = async () => {
    const bundleId = grassdoorBundle && productDetails?.bundle_id;
    const [resAsap, resSchedule] =
      (productRecommendationsAsap.slug || productRecommendationsSchedule.slug) === slug
        ? [productRecommendationsAsap, productRecommendationsSchedule]
        : await Promise.all([getRecommendedProductsByIdASAP(slug, bundleId, grassdoorBundle), getRecommendedProductsByIdSchedule(slug, bundleId, grassdoorBundle)]);
    if (tab === 'asap' && !resAsap) {
      setViewTab('schedule');
    }

    if (tab === 'schedule' && !resSchedule) {
      setViewTab('asap');
    }

    if (!resAsap?.length && !resSchedule?.length) {
      if (grassdoorBundle && !bundleId) {
        setHideLoader(false);
        return;
      }
      setHideLoader(true);
    }
    setRecommendedProductsByIdAsap(resAsap);
    setRecommendedProductsByIdSchedule(resSchedule);
  };

  const updateRecommendedProducts = async () => {
    const data = await getRecommendedProductsBySlug(slug);
    setRecommendedProducts(data);
    setHideLoader(true);
  };

  const strainDetails = getStrainDetails(productDetails.product_strain_type_name || '');
  return (
    <>
      <div className={`${grassdoorProduct || grassdoorBundle ? 'product-page' : 'product-page ff'} container`}>

        {isNewHomePageApplicable && showNavbar ? <SearchButton hideFilter /> : null}
        {loading ? (
          <div className="modal-body pdp-loader" >
            <div className='loader-wrapper'>
              <InlineLoader text='Loading...' />
            </div>
          </div>
        ) : (
          <>
            <div className={`inner row ${DTCproduct ? '' : 'mt-lg-5 vertical-line py-2 py-lg-0'} ${isNewHomePageApplicable ? 'outer-layer-pdp' : ''}`}>

              {(grassdoorProduct || ((DTCproduct && process.env.NEXT_PUBLIC_DEFAULT_SITES)) && !grassdoorBundle && !DTCBundle) && !productPresent ? (
                <div className={isNewHomePageApplicable ? 'col-12' : ''}>
                  {!isEmpty(recommendedProductsByIdAsap) ||
                    !isEmpty(recommendedProductsByIdSchedule) ||
                    !isEmpty(recommendedProducts) ? (
                    <SimilarProducts
                      className="similar-products"
                      openInPopup={openInPopup}
                      modalRef={modalRef}
                      recommendedProducts={recommendedProducts}
                      recommendedProductsByIdAsap={recommendedProductsByIdAsap}
                      recommendedProductsByIdSchedule={recommendedProductsByIdSchedule}
                      responseTab={viewTab}
                      currentProduct={productDetails}
                      onlySimilar
                    />
                  ) : (
                    !hideLoader && (
                      <div className="text-center my-2 mb-4 pb-4">
                        {/* <InlineLoader positionRelative className="big" /> */}
                      </div>
                    )
                  )
                  }
                </div>
              ) : null}
              {isNewHomePageApplicable ? <div className="col-12 pdp-top-info">
                <div>

                  <h1 className="product-name">
                    {productDetails.bundle_name || productDetails.product_name}

                    {!appConfig?.hideProductWeight && !productDetails.bundle_id ? (
                      <span>
                        <span className="yellow-divider">{" | "}</span>
                        <span>
                          {productDetails.product_unit === '1/8 oz' || productDetails.product_unit === '1/2 oz' ? productDetails.product_unit : `${productDetails.product_weight || 0}${productDetails.product_unit}`}
                        </span>
                      </span>
                    ) : null}

                  </h1>

                  {!productDetails.bundle_id && productDetails?.brands?.[0]?.slug && productDetails?.brands?.[0]?.name ? (
                    <div className="link-wrap">
                      <a
                        href={`/brands/${productDetails?.brands?.[0]?.slug}`}
                        className="grey-color gd-link"
                      >
                        View more from <span>{productDetails?.brands?.[0]?.name}</span>&nbsp;&nbsp;<span>&#8594;</span>
                      </a>
                    </div>
                  ) : null}
                  {productDetails.product_strain_type_name ? (
                    <div className={`strain-name ${strainDetails.colour}`}>
                      <span className={`${strainDetails.icon}`} />
                      <span>{` ${productDetails.product_strain_type_name}`}</span>
                    </div>
                  ) : null}
                </div>
              </div> : null}
              <div className="col-12 col-lg-6 ">
                {/* {DTCproduct ? (
                  <h6 className="grey-color cat-name">{productDetails ? productDetails.category_name : null}</h6>
                ) : null} */}

                <ImageGridCarousel productDetails={productDetails} openInPopup={openInPopup} />
              </div>
              <div className="col-12 col-lg-6 p-right-sec">
                <ProductContent
                  DTCproduct={DTCproduct}
                  slug={slug}
                  page={page}
                  productDetails={productDetails}
                  openInPopup={openInPopup}
                  openBrandPage={openBrandPage}
                  showTag={showTag}
                  title={title}
                  isMostPopularProduct={isMostPopularProduct}
                  isFeaturedProduct={isFeaturedProduct}

                />
              </div>
            </div>
            {/* {DTCproduct &&
              [WEBSITES.DRINKCANN, WEBSITES.KIKOKO, WEBSITES.PLUSPRODUCTS, WEBSITES.KOAN].includes(process.env.NEXT_PUBLIC_APP) ? (
              <Prop65 className="drinkcann-prop-65-bottom kikoko-prop-65-bottom plus-products-prop-65-bottom" />
            ) : null} */}
            {(grassdoorProduct || grassdoorBundle || (DTCproduct && process.env.NEXT_PUBLIC_DEFAULT_SITES)) && !DTCBundle ? (
              <div>
                {!isEmpty(recommendedProductsByIdAsap) ||
                  !isEmpty(recommendedProductsByIdSchedule) ||
                  !isEmpty(recommendedProducts) ? (
                  <SimilarProducts
                    className="shop-full-menu"
                    openInPopup={openInPopup}
                    modalRef={modalRef}
                    recommendedProducts={recommendedProducts}
                    recommendedProductsByIdAsap={recommendedProductsByIdAsap}
                    recommendedProductsByIdSchedule={recommendedProductsByIdSchedule}
                    responseTab={viewTab}
                    currentProduct={productDetails}
                    otherThanSimilar={!productPresent}
                  />
                ) : (
                  !hideLoader && (
                    <div className="text-center my-2 mb-4 pb-4">
                      <InlineLoader positionRelative className="big" />
                    </div>
                  )
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
      {DTCproduct ? <WunderFooter /> : null}
      {process.env.NEXT_PUBLIC_APP === WEBSITES.CANNADIPSTHC ? <CannadipsFooter /> : null}
    </>
  );
};

export default ProductBundleContainer;
