import { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE } from "../constants/sales";

export function calculateTotalPriceWithTax(products) {
    let totalPreTax = 0;
    let totalPostTax = 0;

    products.forEach(product => {
        const {
            price = 0,
            quantity = 1,
            original_price: orignalPrice = 0,
            concentrated_weight,
            non_concentrated_weight
        } = product;

        // Initial price calculation logic (pre-tax)
        const priceToUse = orignalPrice && orignalPrice !== 0 ? orignalPrice : price;
        const productTotalPreTax = priceToUse * quantity;
        totalPreTax += productTotalPreTax;

        // Determine if the product is cannabis-based
        const isCannabisProduct = concentrated_weight > 0 || non_concentrated_weight > 0;

        // Apply tax automatically based on product type
        let productTotalPostTax = productTotalPreTax;
        if (isCannabisProduct) {
            productTotalPostTax += productTotalPostTax * EXCISE_TAX_RATE;
            productTotalPostTax += productTotalPostTax * LOCAL_CANNABIS_TAX_RATE;
        }
        productTotalPostTax += productTotalPostTax * SALES_TAX_RATE;

        // Accumulate post-tax total
        totalPostTax += productTotalPostTax;
    });

    return totalPostTax.toFixed(2);
}