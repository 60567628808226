import React, { useRef, useEffect, useState, useContext, Fragment } from 'react';
import { SearchBox, Hits, Highlight, connectStateResults, connectRefinementList, connectStats } from 'react-instantsearch-dom';
import { useShopFilters } from '../hooks/Shop';
import { useDeliveryDetails, useZipCodeStatus } from '../hooks/app';
import useSegment from '../hooks/segment';
import appContext from '../Context/appContext';
import { useRouter } from 'next/router';
import { SCHEDULE } from '../constants/deliveryType';
import filter from 'lodash/filter';
import LoadImage from './LoadImage';
import * as PRODUCT_ATTR_TYPE from '../constants/productAttributeType';
import HraderDeliveryAddress from './DTC/bento/HraderDeliveryAddress';
import find from 'lodash/find';
import FilterBox from './FilterBox';
import { getCategoryUrl } from '../helpers/categoriesHelper';
import FilterContent from './search/FilterContent';
import FilterDrawer from './search/FilterDrawer';
import notificationTypes from '../constants/modalNotificationConst';
import * as WEBSITES from "../constants/website"
import { useDispatch, useSelector } from 'react-redux';
import { pushIfNotPresent, pushNotification, popNotification, increaseURLstack } from '../redux/slices/modalSlice';
import { setSearchTabs, setUrlBeforeProduct } from '../redux/slices/userSlice';
import { setAllCartProductsWidget } from '../redux/slices/cartSlice';
const Hit = ({ hit }) => {
  // const {
  //   // state: {
  //   //   user: { searchSelectedTab }
  //   // },
  //   dispatch } = useContext(appContext);
  const searchSelectedTab = useSelector(state => state.user.searchSelectedTab)
  const dispatchRedux = useDispatch()
  const {
    data: { locationId }
  } = useZipCodeStatus();
  const router = useRouter();
  let showCustomeName = false;
  if (hit.custom_name) {
    showCustomeName = true;
  }
  let showSoldOut = false;
  if (searchSelectedTab == SCHEDULE) {
    let showSoldOutOnSearch = hit.location_specific_details && hit.location_specific_details[`${locationId}`] && hit.location_specific_details[`${locationId}`]['show_on_search_if_sold_out'] ?
      hit.location_specific_details[`${locationId}`]['show_on_search_if_sold_out']['schedule_status'] : 0;
    if (showSoldOutOnSearch == 2) {
      showSoldOut = true;
    }
    else if (showSoldOutOnSearch == 3) {
      return null;
    }
  }
  function routeURL() {
    let data = hit;
    if (
      [
        WEBSITES.KOAN,
        WEBSITES.DRINKCANN,
        WEBSITES.WUNDER,
        WEBSITES.CANNADIPSTHC,
        WEBSITES.BEED,
        WEBSITES.POTLI,
        WEBSITES.ALTLIFE,
        WEBSITES.HELLOAGAIN,
        WEBSITES.VETCBD,
        WEBSITES.HUMBOLDTFAMILYFARMS,
        WEBSITES.MAISONBLOOM,
        WEBSITES.BOASTCANNABIS
      ].includes(process.env.NEXT_PUBLIC_APP)
    ) {
      if (data.bundle_id) {
        router.push('/bundle/[slug]', `/bundle/${data.slug}`);
      } else {
        router.push('/product/[slug]', `/product/${data.slug}`);
      }
    } else {
      // dispatch({ type: 'setAllCartProductsWidget', payload: false });
      dispatchRedux(setAllCartProductsWidget(false))
      let url = '';
      // dispatch({
      //   type: 'setUrlBeforeProduct',
      //   payload: { urlBeforeProduct: `${window.location.pathname + window.location.search}` }
      // });
      dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))
      let isBundle = true;
      if (data.bundle_id) {
        url = `/bundle/${data.slug}`;
      } else {
        isBundle = false;
        url = `/product/${data.slug}`;
      }
      dispatchRedux(popNotification())

      dispatchRedux(pushNotification({ type: notificationTypes.productDetails, data: { slug: data.slug, page: '', isBundle } }))
      dispatchRedux(increaseURLstack())
      window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
    }
  }
  return (
    <div className="">
      <div onClick={routeURL}>
        <LoadImage src={hit.imageUrl} height={60} width={60} alt={hit.firstname} />
        <div className="hit-name">
          {
            showCustomeName ? <Highlight attribute="custom_name" hit={hit} /> :
              <Highlight attribute="name" hit={hit} />
          }
        </div>
        {showSoldOut ? <div className='soldout-limit' style={{ 'fontSize': 13 }}>Sold out</div> : null}
        <div className="">
          {hit.full_shop_categories && hit.full_shop_categories.length ? (
            <small className="hit-category vertical-align-middle medium-grey">
              <span>Category: </span>
              <Highlight attribute="full_shop_categories" hit={hit} />
            </small>
          ) : null}
          {hit.brands && hit.brands.length ? (
            <Fragment>
              <span className="ml-1 mr-1 bull">&bull;</span>
              <span className="hit-brand vertical-align-middle  medium-grey">
                Brands:
                {' '}
                <Highlight attribute="brands" hit={hit} />
              </span>
            </Fragment>
          ) : null}
          {hit.strain && hit.strain.length ? (
            <Fragment>
              <span className="ml-1 mr-1 bull">&bull;</span>
              <span className="hit-brand vertical-align-middle medium-grey">
                Strain:
                {' '}
                <Highlight attribute="strain" hit={hit} />
              </span>
            </Fragment>
          ) : null}
        </div>
        <small className=" weight medium-grey weight-category">
          {hit.unitValue}
          &nbsp;
          {hit.unitType}
          {hit.attributeContent && hit.attributeContent.length
            ? hit.attributeContent.map(attribute => {
              switch (attribute.type) {
                case PRODUCT_ATTR_TYPE.percentage: {
                  return ` - ${attribute.name} : ${attribute.value} %`;
                }
                case PRODUCT_ATTR_TYPE.range: {
                  const rangeValue = attribute.value.split(',');
                  if (rangeValue && rangeValue.length && rangeValue.length == 2) {
                    return ` - ${attribute.name} : ${rangeValue[0]}-${rangeValue[1]}%`;
                  }
                  return '';
                }
                case PRODUCT_ATTR_TYPE.miligram: {
                  return ` - ${attribute.name} : ${attribute.value} mg`;
                }
                default: {
                  return ` - ${attribute.name} : ${attribute.value}`;
                }
              }
            })
            : null}
        </small>
      </div>
    </div>
  );
};

const RefinementListDeliveryTypeChange = ({ items, refine }) => {

  // const { state: {
  //   user: { searchSelectedTab }
  // } } = useContext(appContext)

  const searchSelectedTab = useSelector(state => state.user.searchSelectedTab)

  const typeActive =
    filter(items, function (o) {
      return o.count;
    }) || {};
  if (items && items.length)
    return (
      <a
        onClick={event => {
          // event.preventDefault();
          refine([typeActive[0].label]);
        }}
      >
        {` in ${searchSelectedTab == 'schedule' ? 'Schedule' : 'ASAP'} Menu.`}
        <br />
        {` Check `}
        <span className="cursor-pointer text-primary">{`${searchSelectedTab == 'schedule' ? 'ASAP' : 'Schedule'} Menu`}</span>
        {` for available products`}
      </a>
    );
  return '.';
};

const ChangeType = connectRefinementList(RefinementListDeliveryTypeChange);

const Content = connectStateResults(({ searchState, searchResults }) => {
  const {
    data: { locationId }
  } = useZipCodeStatus();
  return searchResults && searchResults.nbHits !== 0 ? (
    <Hits hitComponent={Hit} />
  ) : (
    <div className="no-results px-2 text-center">
      {`No results has been found for "${searchState.query}"`}
      <ChangeType attribute={`location_specific_details.${locationId}.delivery`} />
    </div>
  );
});

const CustomStats = connectStats(({ nbHits }) => {
  if (nbHits) {
    return (
      <div className="f-left-sec d-none d-lg-flex">
        <FilterContent />
      </div>
    );
  }
  return null;
});

const CustomStatsMobile = connectStats(({ nbHits, openFilterDrawer }) => {
  if (nbHits) {
    return (
      <div
        className="sidebarItemStyle  filter-btn cursor-pointer d-lg-none filter-btn-mobile"
        role="presentation"
        onClick={openFilterDrawer}
      >
        <span className="icon icon-filter" />
        <span className="text">&nbsp;Filter</span>
        {/* {filterApplied && <span className="filter-indicator" />} */}
      </div>
    );
  }
  return null;
});

const SearchInputBox = ({ defaultSearchTab }) => {
  const [searchString, setSearchString] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [showSearchBoxContent, setShowSearchBoxContent] = useState(false);
  const [focussed, setFocussed] = React.useState(false)
  // const {
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const notifications = useSelector(state => state.modal.notifications)
  const searchRef = useRef(null);
  const { data: filterList } = useShopFilters({});
  const { data: { locationId, validZipcode } } = useZipCodeStatus();
  const {
    data: { happyHours }
  } = useDeliveryDetails(false, true)
  const { is_enable: is_happy_hours_enable, end_time } = happyHours || {};

  const showSearch = (e, type) => {
    setShowSearchBoxContent(type)
  }

  const onSearchStateChange = (event) => {
    setShowSearchBoxContent(true)
    if (searchString != !!event.target.value) {
      setSearchString(!!event.target.value);
    }
  }

  function toggleFilterDrawer() {
    setFilterDrawerOpen(!filterDrawerOpen);
  }
  useEffect(() => {
    setTimeout(() => {
      setShowSearchBox(true);
    }, 100);
  }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (searchRef.current && !searchRef.current.contains(event.target)) {
  //       setShowSearchBoxContent(false)
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, []);

  const categoryList = find(filterList, { title: 'Categories' });

  function openFilterDrawer() {
    // const { shopStore } = this.context;
    // shopStore.toggleFilterDrawer();
    setFilterDrawerOpen(true);
  }

  function openSearchPopup() {
    if (validZipcode) {
      dispatchRedux(pushIfNotPresent({ type: notificationTypes.searchModal, data: { defaultSearchTab } }))
    } else {
      const addressModalOpen = notifications
        .map(notification => notification.type)
        .includes(notificationTypes.deliverAddressPopup);
      if (!addressModalOpen) {
        dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
      }
    }
  }

  function handleSearchFocus() {
    if (validZipcode) return;
    const addressModalOpen = notifications
      .map(notification => notification.type)
      .includes(notificationTypes.deliverAddressPopup);
    if (!addressModalOpen) {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    }
  }

  useEffect(() => {
    const currentRef = searchRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio === 0) {
          // dispatch({ type: 'setSearchTabs', payload: true });
          dispatchRedux(setSearchTabs(true))
        } else {
          // dispatch({ type: 'setSearchTabs', payload: false });
          dispatchRedux(setSearchTabs(false))
        }
      },
      {
        root: null,
        rootMargin: '0%',
        threshold: 0
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [searchRef.current]);


  return (
    <div onClick={() => setFocussed(true)} className='new-search-component'>
      <div ref={searchRef} className="input-group searchBar">
        <HraderDeliveryAddress className={is_happy_hours_enable ? "mt-2" : ""} />
        <div onClick={openSearchPopup} className='mobile-search-box'>
          <p className="w-100 d-flex align-items-center mb-0">Search</p>
          {/* <p className="w-100 d-flex align-items-center mb-0">Search {appConfig.APP_NAME}</p> */}
          <i className='icon-search' />
        </div>
        <SearchBox
          translations={{
            placeholder: 'Try "Indica" or "Hybrid"'

          }}
          onChange={onSearchStateChange}
          // autoFocus
          // onFocus={() => handleSearchFocus()}
          onReset={() => {
            setSearchString(false);
          }}
        />

        {focussed && searchString && showSearchBoxContent ? <div className='search-result-wrapper'>
          <div className="filter-items-holder mt-0 d-lg-flex">
            {searchString && <CustomStats />}
            <div className="f-right-sec">
              {searchString && (
                <div className={`d-flex justify-content-between align-items-center filter-container `}>
                  <FilterBox defaultSearchTab={defaultSearchTab} />
                  <CustomStatsMobile openFilterDrawer={openFilterDrawer} />
                </div>
              )}
              {searchString && (
                // <div className={`${searchString ? '' : 'd-none'}`}>
                <Content />
              )}
            </div>
          </div>
        </div> : null
        }

        <FilterDrawer filterDrawerOpen={filterDrawerOpen} toggleFilterDrawer={toggleFilterDrawer}>

          <FilterContent toggleFilterDrawer={toggleFilterDrawer} locationId={locationId} filterList={filterList} />
        </FilterDrawer>
      </div>
    </div >




  )
}
export default SearchInputBox