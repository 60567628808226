import * as WEBSITES from '../constants/website';

export const setFirstBanner = bannerData => {
  // const clonebannerData = { ...bannerData };
  // if ([WEBSITES.GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP)) {
  //   if (clonebannerData?.asap?.primary?.[0]?.id !== 31) {
  //     clonebannerData?.asap?.primary?.unshift(
  //       {
  //         desktop_image_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1665982877.jpg',
  //         desktop_image_thumb_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1665982877.jpg',
  //         mobile_image_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1665982877.jpg',
  //         mobile_image_thumb_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1665982877.jpg',
  //         header: 'California’s Most Trusted<br/>Weed Delivery Service',
  //         sub_header: 'Fastest Cannabis Delivery<br/>in California',
  //         paragraph: '',
  //         button_text: '',
  //         button_link: '',
  //         name: '',
  //         menu_type: 1,
  //         is_primary: 1,
  //         id: 31,
  //         alt_text: 'California’s Most Trusted Weed Delivery Service.Fastest Cannabis Delivery in California'
  //       }
  //       // {
  //       // 	"desktop_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1660220485.jpg",
  //       // 	"desktop_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"header": "Asap banner 2",
  //       // 	"sub_header": "Asap banner 2",
  //       // 	"paragraph": "",
  //       // 	"button_text": "",
  //       // 	"button_link": "",
  //       // 	"name": "",
  //       // 	"menu_type": 1,
  //       // 	"is_primary": 1,
  //       // 	"id": 31,
  //       // 	"alt_text": "Earn up to $100 per referral. Every customer gets you up to $100 when they order from Grassdoor"
  //       // },
  //       // {
  //       // 	"desktop_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1660220485.jpg",
  //       // 	"desktop_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"header": "Asap banner 3",
  //       // 	"sub_header": "Asap banner 3",
  //       // 	"paragraph": "",
  //       // 	"button_text": "",
  //       // 	"button_link": "",
  //       // 	"name": "",
  //       // 	"menu_type": 1,
  //       // 	"is_primary": 1,
  //       // 	"id": 31,
  //       // 	"alt_text": "California’s Most Trusted Weed Delivery Service.Fastest Cannabis Delivery in California"
  //       // }
  //     );
  //   }
  //   if (clonebannerData?.schedule?.primary?.[0]?.id !== 32) {
  //     clonebannerData?.schedule?.primary?.unshift(
  //       {
  //         desktop_image_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1665982877.jpg',
  //         desktop_image_thumb_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1665982877.jpg',
  //         mobile_image_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1665982877.jpg',
  //         mobile_image_thumb_url:
  //           'https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1665982877.jpg',
  //         header: 'California’s Most Trusted<br/>Weed Delivery Service',
  //         sub_header: 'Fastest Cannabis Delivery<br/>in California',
  //         paragraph: '',
  //         button_text: '',
  //         button_link: '',
  //         name: '',
  //         menu_type: 2,
  //         is_primary: 1,
  //         id: 32,
  //         alt_text: 'California’s Most Trusted Weed Delivery Service.Fastest Cannabis Delivery in California'
  //       }
  //       // {
  //       // 	"desktop_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1660220485.jpg",
  //       // 	"desktop_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"header": "Schedule banner 2",
  //       // 	"sub_header": "Schedule banner 2",
  //       // 	"paragraph": "",
  //       // 	"button_text": "",
  //       // 	"button_link": "",
  //       // 	"name": "",
  //       // 	"menu_type": 2,
  //       // 	"is_primary": 1,
  //       // 	"id": 32,
  //       // 	"alt_text": "Earn up to $100 per referral. Every customer gets you up to $100 when they order from Grassdoor"
  //       // },
  //       // {
  //       // 	"desktop_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/Homepage_banner_1660220485.jpg",
  //       // 	"desktop_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/Homepage_banner_1660220485.jpg",
  //       // 	"mobile_image_thumb_url": "https://prod-serverless.grassdoor.com/filters:format(webp)/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1660220485.jpg",
  //       // 	"header": "Schedule banner 3",
  //       // 	"sub_header": "Schedule banner 3",
  //       // 	"paragraph": "",
  //       // 	"button_text": "",
  //       // 	"button_link": "",
  //       // 	"name": "",
  //       // 	"menu_type": 2,
  //       // 	"is_primary": 1,
  //       // 	"id": 32,
  //       // 	"alt_text": "California’s Most Trusted Weed Delivery Service.Fastest Cannabis Delivery in California"
  //       // }
  //     );
  //   }
  // }

  return bannerData;
};
