import io from 'socket.io-client';
import cookie from 'js-cookie';

import { DEFAULT_ZIPCODE } from '../constants/default';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import SocketEmitConst from '../constants/socketConst';
import storageService from '../services/storageService';
import { setRollbarWarning } from './rollBar';

let socket = null;
let authorization = null;
let isConnecting = false;
let getCategoriesTimeout = null;

export const getSocket = () => socket;

export const getUpdatedZone = callback => {
  socket.on(SocketEmitConst.UPDATE_ZONE_MENU, data => {
    callback(data);
  });
};

export const removeListnerUpdatedZone = () => {
  if (socket) {
    socket.removeAllListeners(SocketEmitConst.UPDATE_ZONE_MENU);
  }
};

export const isSocketConnected = () => {
  if (socket) {
    return socket.connected;
  }
  return false;
};

export const setSocketReconnected = () => {
  // When socket is reconnected
  socket.on(SocketEmitConst.RECONNECT, () => {
    isConnecting = false;
  });
};

export const socketConnect = callback => {
  if (!isSocketConnected() && !isConnecting) {
    isConnecting = true;

    const cookieToken = cookie.get(COOKIE_VARIABLES.token) || '';

    const socketConfig = {
      secure: true,
      // query: cookieToken ? `token=Bearer ${cookieToken}` : null
      query: null
    };

    if (process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true') {
      socketConfig.transports = ['websocket'];
    }

    socket = io(process.env.NEXT_PUBLIC_SOCKET_EC2_URL, socketConfig);

    socket.once(SocketEmitConst.CONNECT, () => {
      isConnecting = false;
      // Once socket is connected
      /* if (AppService.isLoggedIn()) {
        // makeStatusRequest();
      } */
      if (callback) {
        callback(SocketEmitConst.CONNECT, 'Socket Connected');
      }
    });

    setSocketReconnected();

    socket.on(SocketEmitConst.DISCONNECT, error => {
      isConnecting = false;
    });

    socket.on(SocketEmitConst.CONNECT_TIMEOUT, timeoutValue => {
    });

    socket.on(SocketEmitConst.CONNECT_ERROR, error => {
      isConnecting = false;
    });

    // If Authentication Error
    socket.on(SocketEmitConst.AUTH_ERROR, () => {
      socket.disconnect();
      // Logout from App
      storageService.deleteToken();
      storageService.logoutClearUserData();
    });

    socket.on(SocketEmitConst.ERROR, error => {
      isConnecting = false;
    });

    socket.on(SocketEmitConst.ERROR_RESPONSE, error => {
    });
  }
  /* else if (AppService.isLoggedIn()) {
    // makeStatusRequest();
  } */
};

export const SocketEmitEvent = (data, callback, timeout = 10000, counter = 1) => {
  if (isSocketConnected()) {
    const interval = setTimeout(() => {
      if (counter <= 3) {
        SocketEmitEvent(data, callback, timeout, counter + 1);
      } else {
        callback({ error: 'Connection error, Please reload the page.' });
      }
    }, timeout);
    const eventName = data.eventName;
    const params = data.params;

    // Pass postcode to every emit
    const localAddress = storageService.getAddressLocalStorage();
    if (localAddress && localAddress.postcode) {
      params.zc = localAddress.postcode;
    } else {
      params.zc = DEFAULT_ZIPCODE;
    }

    if (storageService.isLoggedIn() && process.browser) {
      params.authorization = `Bearer ${cookie.get(COOKIE_VARIABLES.token)}`;

      socket.emit(eventName, params, res => {
        clearTimeout(interval);
        if (callback) {
          if (res.error) {
            if ((Array.isArray(res.error) || typeof res.error === 'string') && res.error.includes('Authorization failed')) {
              storageService.deleteToken();
              storageService.logoutClearUserData();
            }
          }
          callback(res);
        }
      });
    }
  } else {
    socketConnect(() => {
      if (counter <= 3) {
        SocketEmitEvent(data, callback, timeout, counter + 1);
      } else {
        callback({ error: 'Connection error, Please reload the page.' });
      }
    });
  }
};

export const requestCustomerStatus = () => {
  return new Promise((resolve, reject) => {
    SocketEmitEvent(
      {
        eventName: SocketEmitConst.CUSTOMER_STATUS_CHECK,
        params: {}
      },
      res => {
        if (res) {
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        }
      }
    );
  });
};

// Join Room Request
export const joinRoom = (roomName, callback) => {
  SocketEmitEvent(
    {
      eventName: SocketEmitConst.JOIN_ROOM,
      params: {
        room_name: roomName
      }
    },
    res => {
      if (res) {
        if (res.error) {
          callback(null, res.error);
        } else {
          callback(res.data.success, null);
        }
      }
    },
    5000
  );
};

export const searchdriver = (orderId, callback) => {
  SocketEmitEvent(
    {
      eventName: SocketEmitConst.SEARCH_DRIVER,
      params: {
        order_id: orderId
      }
    },
    res => {
      if (res) {
        if (res.error) {
          callback(null, res.error);
        } else {
          callback(res.data.success, null);
        }
      }
    }
  );
};

export const getdriverlocation = (orderId, callback) => {
  authorization = `Bearer ${cookie.get(COOKIE_VARIABLES.token)}`;

  // Find post code to pass in params
  let paramsZip = DEFAULT_ZIPCODE;
  const localAddress = storageService.getAddressLocalStorage();
  if (localAddress.zipcode) {
    paramsZip = localAddress.zipcode;
  }

  socket.emit(
    SocketEmitConst.GET_DRIVER_LOCATION,
    {
      authorization,
      order_id: orderId,
      zc: paramsZip
    },
    res => {
      if (res) {
        if (res.error) {
          callback(null, res.error);
        } else {
          callback(res.data, null);
        }
      }
    }
  );
};

export const getOrderETA = (orderId, callback) => {
  authorization = `Bearer ${cookie.get(COOKIE_VARIABLES.token)}`;

  // Find post code to pass in params
  let paramsZip = DEFAULT_ZIPCODE;
  const localAddress = storageService.getAddressLocalStorage();
  if (localAddress.zipcode) {
    paramsZip = localAddress.zipcode;
  }

  socket.emit(
    SocketEmitConst.GET_ORDER_ETA,
    {
      authorization,
      order_id: orderId,
      zc: paramsZip
    },
    res => {
      if (res) {
        if (res.error) {
          callback(null, res.error);
        } else {
          callback(res.data, null);
        }
      }
    }
  );
};

export const cancelRequest = (orderId, reasonID, reason, callback) => {
  SocketEmitEvent(
    {
      eventName: SocketEmitConst.CANCEL_ORDER,
      params: { order_id: orderId, cancel_reason_id: reasonID, reason }
    },
    res => {
      if (res) {
        if (res.error) {
          setRollbarWarning('SocketHelper.js_cancelRequest_error :', res);
          console.error('SocketHelper.js_cancelRequest_error  :', res);
          callback(null, res);
        } else {
          callback(res.data, null);
        }
      }
    },
    30000
  );
};

export const checkOut = (chechOutObj, callback) => {
  return new Promise((resolve, reject) => {
    SocketEmitEvent(
      {
        eventName: SocketEmitConst.PLACE_ORDER,
        params: chechOutObj
      },
      res => {
        if (res) {
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        }
      },
      30000
    );
  });
};

export const purchaseGiftCard = (chechOutObj, callback) => {
  return new Promise((resolve, reject) => {
    SocketEmitEvent(
      {
        eventName: SocketEmitConst.PURCHASE_GIFTCARD,
        params: chechOutObj
      },
      res => {
        if (res) {
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        }
      },
      30000
    );
  });
};

export const reorder = (chechOutObj, callback) => {
  SocketEmitEvent(
    {
      eventName: SocketEmitConst.PLACE_RE_ORDER,
      params: chechOutObj
    },
    res => {
      if (res) {
        if (res.error) {
          callback(null, res);
        } else {
          callback(res.data, null);
        }
      }
    },
    30000
  );
};

export const payByCard = paymentObject =>
  new Promise((resolve, reject) => {
    SocketEmitEvent(
      {
        eventName: SocketEmitConst.MAKE_PAYMENT_USING_CARD,
        params: paymentObject
      },
      res => {
        if (res) {
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        }
      }
    );
  });

export const getCategories = (zipcode, callback, host) => {
  const localToken = cookie.get(COOKIE_VARIABLES.token);
  authorization = localToken ? `Bearer ${localToken}` : null;

  // Find post code to pass in params
  let paramsZip = zipcode || DEFAULT_ZIPCODE;
  const localAddress = storageService.getAddressLocalStorage();
  if (localAddress.postcode) {
    paramsZip = localAddress.postcode;
  }

  clearTimeout(getCategoriesTimeout);
  getCategoriesTimeout = setTimeout(() => {
    callback('Server Timeout', null);
    callback = null;
  }, 10000);

  socket.emit(
    SocketEmitConst.JOIN_ROOM,
    {
      authorization,
      zip_code: paramsZip,
      zc: paramsZip,
      'x-origin': host
    },
    res => {
      clearTimeout(getCategoriesTimeout);
      if (res) {
        if (res.error) {
          if (callback) {
            callback(res.error, null);
          }
        } else if (callback) {
          callback(null, res);
        }
      }
    }
  );
};
