import React, { useContext } from 'react';
import InputComponent from './InputComponent';
import alwaysTwoDigit from '../../helpers/alwaysTwoDigit';
import UseEmailVerification from '../../hooks/useEmailVerification';

import { useConfig } from '../../hooks/app';

import appContext from '../../Context/appContext';

// import appConfig from '../../appConfig';
import storageService from '../../services/storageService';
import { useSelector } from 'react-redux';
import { useConfigData } from '../../Context/ConfigProvider';

// Checking for appConfig.emailVerificationFlag from JSON bucket file to enable email verificcation
const EmailComponent = ({ required }) => {
  const userTempData = JSON.parse(storageService.getItem('userTempData')) || {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  };
  // const {
  //   state: {
  //     user: { isEmailVerified, isLoggedIn },
  //     cart: {
  //       customerData: { email: localSorageEmail, emailError: emailErrorCart },
  //       cartData: { email_verified: serverVerifiedEmail, email_required: emailRequired } = {}
  //     }
  //   }
  // } = useContext(appContext);
  const appConfig = useConfigData();
  const { isEmailVerified, isLoggedIn } = useSelector(state => state.user)
  const {
    customerData: { email: localSorageEmail, emailError: emailErrorCart } = {},
    cartData: { email_verified: serverVerifiedEmail, email_required: emailRequired } = {}
  } = useSelector(state => state.cart) || {}

  const {
    data: { promo_code_discount: promoCodeDiscount }
  } = useConfig();

  const {
    formData: { email, emailError, otp, otpError, confirmingOTP, requestingOTP, displayOtpField },
    remainingTime,
    requestEmailOTP,
    setEmail,
    setOTP
  } = UseEmailVerification(localSorageEmail || userTempData.email);

  const isInCoolDown = remainingTime > 0;

  const emailSaved = serverVerifiedEmail || isEmailVerified;

  return (
    <>
      <InputComponent
        id="customer-email"
        className={`${emailSaved ? '' : ''} email-input-parent mb-1 relative`}
        inputClassName={emailError || emailErrorCart ? 'invalid-input' : ''}
        icon="icon-contact"
        disabled={!!emailSaved}
        value={email}
        name="email"
        label="Email"
        type="email"
        maxLength="320"
        placeholder={'Email'}
        onChange={setEmail}
        loading={requestingOTP}
        loadingText="Requesting OTP"
        error={emailError || emailErrorCart}
        required={required}
        otherAttributes={{
          'test-id': '#customer-email'
        }}
      >
        {appConfig.EMAIL_VERIFICATION_FLAG &&
          isLoggedIn &&
          emailRequired &&
          !requestingOTP &&
          !emailSaved &&
          process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' && (
            <button type="button" onClick={requestEmailOTP} className="btn btn-sm small-button verify" disabled={isInCoolDown}>
              {isInCoolDown ? `Resend (00:${alwaysTwoDigit(remainingTime)})` : 'Verify'}
            </button>
          )}
      </InputComponent>

      {appConfig.EMAIL_VERIFICATION_FLAG &&
        isLoggedIn &&
        emailRequired &&
        !emailSaved &&
        process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' && <VerifyEmailMessage promoDiscount={promoCodeDiscount} />}

      {displayOtpField && !emailSaved && (
        <div className="mt-3">
          <InputComponent
            className="relative"
            inputClassName="special-input "
            icon="icon-otp2"
            value={otp}
            type="tel"
            maxLength="4"
            placeholder="Enter Email Verification Code"
            label="Email Verification Code"
            onChange={setOTP}
            loading={confirmingOTP}
            loadingText="Verifying code"
            error={otpError}
          />
        </div>
      )}
    </>
  );
};

const VerifyEmailMessage = ({ promoDiscount }) => (
  <div className="padding-10-vertical md-grey-color custo-info-font verify-email">
    *Verify your email and get&nbsp;
    <strong className="text-primary">{`$${promoDiscount}`}</strong>
    &nbsp;off on your next order
  </div>
);

export default EmailComponent;
