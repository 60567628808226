import React from 'react';
import dynamic from 'next/dynamic';

import { GRASSDOOR, GOVANA, GETHERB, SMOKELAND, FLOWERANDEDIBLES, DEFAULT } from '../../constants/website';

const BentoHeader = dynamic(() => import('./bento/Header'));
const NotGrassdoorHeader = dynamic(() => import('./notGrassdoor/Header'));

const Header = props => {
  switch (process.env.NEXT_PUBLIC_APP) {

    case GRASSDOOR:
    case GOVANA:
    case GETHERB:
    case SMOKELAND:
    case DEFAULT:
    case FLOWERANDEDIBLES:
      return <BentoHeader {...props} />;

    default:
      if (process.env.NEXT_PUBLIC_DEFAULT_SITES) {
        return <BentoHeader {...props} />;
      }
      return <NotGrassdoorHeader {...props} />;
  }
}

export default Header;
