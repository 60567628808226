// redux/rewardsSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isWalletCreditShown: false,
    isFilledRewardNotifcation: false,
    currentAvailedWalletReward: 0,
    currentUnlockedTier: 0,
    currentSubtotal: 0,
    requiredSubtotal: 0,
    rewardAmount: 0,
    rewardStatus: 'away',
    hasUnlockedReward: false, // New state to track if the reward has been unlocked and shown
    rewardUnlocked: null
}
const rewardsSlice = createSlice({
    name: 'rewards',
    initialState: initialState,
    reducers: {
        setRewardUnlocked(state, action) {
            state.rewardUnlocked = action.payload
        },
        setWalletCreditShown(state, action) {
            state.isWalletCreditShown = action.payload;
        },
        setFilledRewardNotification(state, action) {
            state.isFilledRewardNotifcation = action.payload;
        },
        setWalletRewards(state, action) {
            const { walletRewards = {}, nextReward = {}, currentSubtotal = 0 } = action.payload;

            state.currentAvailedWalletReward = walletRewards.amount || 0;
            state.currentUnlockedTier = walletRewards.tier_unlock_subtotal_amount || 0;
            state.currentSubtotal = currentSubtotal || 0;
            state.requiredSubtotal = nextReward.required_subtotal || 0;
            state.rewardAmount = nextReward.amount || 0;
        },
        setRewardStatus(state, action) {
            state.rewardStatus = action.payload;
        },
        setHasUnlockedReward(state, action) { // New action to track if the reward has been unlocked
            state.hasUnlockedReward = action.payload;
        },
        resetRewardsState(state) {
            return initialState; // Reset all state values to the initial state
        }
    }
});

export const {
    setWalletCreditShown,
    setFilledRewardNotification,
    setRewardUnlocked,
    setWalletRewards,
    setRewardStatus,
    setHasUnlockedReward,
    resetRewardsState
} = rewardsSlice.actions;

export default rewardsSlice.reducer;
