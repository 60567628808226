import React, { useRef, useEffect, useContext, useState } from 'react';
import Product from '../../pages/product/[slug]';
import appContext from '../../Context/appContext';
import { BENTO, FLOWERANDEDIBLES, GANJAGODDESS, GRASSDOOR, DEFAULT } from '../../constants/website';
import BundlePage from '../../pages/bundle/[slug]';
import { useBundleDetails, useProductDetails } from '../../hooks/Shop';
import { useDispatch, useSelector } from 'react-redux';
import { setFavoriteStatusChangedForProduct, setScrollPositionPdp, setUrlBeforeProduct } from '../../redux/slices/userSlice';
import { pushIfNotPresent, pushNotification, popStack } from '../../redux/slices/modalSlice';

const ProductDetailsModal = ({
  slug,
  tab,
  page,
  isBundle,
  showTag,
  dismiss,
  title,
  isMostPopularProduct,
  isFeaturedProduct,
  widgetTypeAdded,
  widgetType
}) => {
  const modalBodyRef = useRef();
  const slugRef = useRef();

  // const {
  //   // state: {
  //   //   user: {
  //   //     urlBeforeProduct,
  //   //     scrollPositionPdp
  //   //   },
  //   //   // cart: { PDPOpenedFromWidget }
  //   // },
  //   dispatch
  // } = useContext(appContext);

  const {
    urlBeforeProduct,
    scrollPositionPdp
  } = useSelector(state => state.user);

  const urlBeforeProductRedux = useSelector(state => state.user.urlBeforeProduct)
  const dispatchRedux = useDispatch()

  // useEffect(() => {
  //   if (PDPOpenedFromWidget) {
  //     dispatch({ type: 'setIsAddToCartLoading', payload: false });
  //     dispatch({ type: 'setPDPOpenedFromWidget', payload: false });
  //   }
  // }, [PDPOpenedFromWidget]);

  const { mutate } = isBundle ? useBundleDetails({ slug }) : useProductDetails({ slug, tab });

  useEffect(() => {
    modalBodyRef.current.scrollTop = scrollPositionPdp || 0;
  }, []);

  useEffect(() => {
    window.onpopstate = e => {
      dispatchRedux(popStack())
      dismiss();
    };
  }, []);

  useEffect(() => {
    slugRef.current = slug;
  });

  useEffect(() => {
    if (slugRef.current !== slug) {
      modalBodyRef.current.scrollTop = 0;
    }

    // To get updated Data on Product details modal
    mutate();
  }, [slug]);

  const handleScroll = () => {
    if (modalBodyRef.current?.scrollTop) {
      // dispatch({ type: 'setScrollPositionPdp', payload: { scrollPositionPdp: modalBodyRef.current.scrollTop } });
      dispatchRedux(setScrollPositionPdp({ scrollPositionPdp: modalBodyRef.current.scrollTop }))
    }
  };

  // useEffect(() => {
  //   const popUpdiv = modalBodyRef?.current;
  //   popUpdiv.addEventListener('scroll', debounce(handleScroll, 300), {passive: true});

  //   return () => {
  //     popUpdiv.removeEventListener('scroll', debounce(handleScroll, 300));
  //   };
  // }, [notifications?.length]);

  function handleClose() {
    window.history.replaceState(
      { ...window.history.state, as: urlBeforeProductRedux || urlBeforeProduct, url: urlBeforeProductRedux || urlBeforeProduct },
      '',
      `${window.location.origin}${urlBeforeProductRedux || urlBeforeProduct}`
    );
    // dispatch({ type: 'setUrlBeforeProduct', payload: { urlBeforeProduct: '' } });
    dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: '' }))
    // dispatch({ type: 'setFavoriteStatusChangedForProduct', payload: { favoriteStatusChangedForProduct: null } });
    dispatchRedux(setFavoriteStatusChangedForProduct({ favoriteStatusChangedForProduct: null }))
    dispatchRedux(popStack())
    // dispatch({ type: 'setScrollPositionPdp', payload: { scrollPositionPdp: 0 } });
    dispatchRedux(setScrollPositionPdp({ scrollPositionPdp: 0 }))
    dismiss();
  }

  return (
    <>
      <div className="modal d-block product-page-popup animated-popup-wrapper" tabIndex="-1" role="dialog">
        <div className="modal-dialog m-scroll " role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center justify-content-between new-product-header">
              {/* <a className="btn left-btn" onClick={this.goBack}>
                    <span className="icon-back" />
                  </a> */}
              {/* <h5 className="modal-title">Product Details </h5> */}
              {/* <button type="button" className="btn " onClick={openSearchPopup}>
                    <i className="icon-search" />
                  </button> */}
              <button test-id="#pdp_close_icon" type="button" className="btn  product-close-btn" onClick={handleClose}>
                <i className="icon-close " />
              </button>
            </div>

            <div className="modal-body " ref={modalBodyRef}>
              {[GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP) ? (
                <>
                  {isBundle ? (
                    <BundlePage
                      slug={slug}
                      tab={tab}
                      showNavbar={false}
                      openInPopup
                      modalRef={modalBodyRef}
                      title={title}
                      isMostPopularProduct={isMostPopularProduct}
                    />
                  ) : (
                    <Product
                      slug={slug}
                      tab={tab}
                      showNavbar={false}
                      openInPopup
                      modalRef={modalBodyRef}
                      title={title}
                      isMostPopularProduct={isMostPopularProduct}
                      isFeaturedProduct={isFeaturedProduct}
                      widgetTypeAdded={widgetTypeAdded}
                      widgetType={widgetType}
                    />
                  )}
                </>
              ) : (
                <>
                  {isBundle ? (
                    <BundlePage slug={slug} tab={tab} showNavbar={false} openInPopup showTag={showTag} />
                  ) : (
                    <Product slug={slug} tab={tab} showNavbar={false} openInPopup showTag={showTag} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsModal;
