import React, { useCallback, useContext, useEffect, useState } from 'react';
import Cookies from 'cookies';
import isEmpty from 'lodash/isEmpty';
import Head from 'next/head';
import { useRouter } from 'next/router';

import grassdoorRedirects from '../../constants/grassdoorRedirects';
import * as COOKIE_VARIABLES from '../../constants/cookieVariables';
import oldProductSlug from '../../constants/oldProductSlug';
import newProductSlug from '../../constants/newProductSlug';
import { DEFAULT_LOCATION, DEFAULT_ZIPCODE } from '../../constants/default';
import * as WEBSITES from '../../constants/website';

import { useBundleDetails } from '../../hooks/Shop';
import getHost from '../../helpers/gethost';
import axios from '../../utils/ajax';

// import appConfig from '../../appConfig';

import ProductContainer from '../../components/DTC/Bundle';
import Header from '../../components/Header';
import Page404 from '../404';
import appContext from '../../Context/appContext';
import pageDisable from '../../helpers/pageDisableHelper';
import { isNewHomePageApplicable } from '../../constants/feature';
import { useCommonFunctions } from '../../hooks/common';
import { popNotification, popStack } from '../../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { withConfig } from '../../lib/withConfig';
import { useConfigData } from '../../Context/ConfigProvider';

const canonicalHref = `https://${getHost()}/bundle/`; //same used for og:url

const BundlePage = ({
  bundleDetails: initialBundleDetails,
  host = false,
  error = false,
  showNavbar = true,
  openInPopup = false,
  slug: overrideSlug = false,
  modalRef = null,
  showTag,
  dismiss = null,
  id,
}) => {
  const router = useRouter();
  const { setTab } = useCommonFunctions()
  const {
    query: { slug: querySlug }
  } = router;
  const appConfig = useConfigData();
  // const {
  //   dispatch
  // } = useContext(appContext);
  const stackURL = useSelector(state => state.modal.stackURL)

  const dispatchRedux = useDispatch()

  const [isSolid, setSolid] = useState(false);

  const initialSlug = overrideSlug || querySlug;

  const {
    data: { bundleDetails },
    isLoading,
    isValidating
  } = useBundleDetails({ slug: initialSlug, initialBundleDetails });

  const {
    images: bundleImages = [],
    thumb_medium_images: bundleThumbMediumImages = [],
    thumb_small_images: bundleThumbSmallImages = [],
    product_unit: bundleProductUnit = '',
    product_weight: bundleProductWeight = '',
    bundle_name: bundleName = '',
    description: bundleDescription = '',
    bundle_id: bundleId = null,
    slug: bundleSlug = '',
    bundle_selling_price: bundleSellingPrice = null,
    original_price: bundleOriginalPrice = null,
    is_sold_out: isBundleSoldOut = false,
    brand_names: bundleBrandNames = [],
    category_name: bundleCategoryName = '',
    meta_title: bundleMetaTitle = '',
    meta_description: bundleMetaDescription = ''
  } = bundleDetails || {};


  const handleScroll = useCallback(() => {
    if (window.scrollY > 150) {
      if (!isSolid) {
        setSolid(true);
      }
    } else if (isSolid) {
      setSolid(false);
    }
  }, [isSolid]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (error) {
    return (
      <>
        <Page404 showNavbar={showNavbar} />
      </>
    );
  }

  if ((!isLoading && !isValidating && isEmpty(bundleDetails)) || (!isLoading && isEmpty(bundleDetails))) {
    return (
      <>
        <Page404 openInPopup={openInPopup} showNavbar={showNavbar} titleMessage="Product not found" subMesaage="" />
      </>
    );
  }

  function renderBundleSchema() {
    if (!bundleDetails) {
      return null;
    }

    const largeImage = bundleImages?.length ? bundleImages[0] : null;
    const mediumImage = bundleThumbMediumImages?.length ? bundleThumbMediumImages[0] : null;
    const smallImage = bundleThumbSmallImages?.length ? bundleThumbSmallImages[0] : null;
    let imageArray = [smallImage, mediumImage, largeImage];
    imageArray = imageArray.filter(image => image);

    let productProperty = null;
    switch (bundleProductUnit) {
      case 'kg':
        productProperty = { unitCode: 'GRM', value: Number(bundleProductWeight) * 1000 };
        break;
      case 'g':
      case 'gram':
      case 'grams':
        productProperty = { unitCode: 'GRM', value: Number(bundleProductWeight) };
        break;
      case 'lb':
        productProperty = { unitCode: 'GRM', value: Number(bundleProductWeight) * 453.592 };
        break;
      case 'oz':
        productProperty = { unitCode: 'ONZ', value: Number(bundleProductWeight) };
        break;
      case '1/8 oz':
        productProperty = { unitCode: 'ONZ', value: 0.125 };
        break;
      case '1/2 oz':
        productProperty = { unitCode: 'ONZ', value: 0.5 };
        break;
      case 'mg':
        productProperty = { unitCode: 'MGM', value: Number(bundleProductWeight) };
        break;
      case 'EA':
      default:
        productProperty = null;
        break;
    }

    const schemaObject = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: bundleName,
      image: imageArray,
      description: bundleDescription,
      sku: String(bundleId),
      mpn: String(bundleId),
      offers: {
        '@type': 'Offer',
        url: `${host}/bundle/${bundleSlug}`,
        price: bundleSellingPrice || bundleOriginalPrice,
        priceCurrency: 'USD',
        availability: isBundleSoldOut ? 'OutOfStock' : 'InStock',
        priceValidUntil: '2021-11-20'
      }
    };

    const brand = bundleBrandNames?.length ? bundleBrandNames[0] : null;
    if (brand) {
      schemaObject.brand = { '@type': 'Brand', name: brand, '@id': `${host}/brands/${bundleBrandNames[0]}` };
    }

    if (productProperty) {
      schemaObject.offers.additionalProperty = {
        '@type': 'PropertyValue',
        name: 'Weight',
        ...productProperty
      };
    }
    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaObject) }} />;
  }

  function openBrandPage(e) {
    e.preventDefault();
    dispatchRedux(popNotification())

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < stackURL; i++) {
      window.history.back();
    }
    dispatchRedux(popStack())
    // eslint-disable-next-line no-restricted-globals
    location.href = `/brands/${bundleBrandNames[0]}`;
  }

  return (
    <>
      <Head>
        {renderBundleSchema()}

        <title>
          {bundleDetails
            ? `${bundleCategoryName ? `${bundleCategoryName} ` : ''}${bundleMetaTitle} - Order Online, Delivered to your Door`
            : appConfig.APP_NAME}
        </title>

        <meta name="description" content={bundleMetaDescription || appConfig.APP_NAME} />
        <meta
          property="og:title"
          content={
            bundleDetails
              ? `${bundleCategoryName ? `${bundleCategoryName} ` : ''} ${bundleMetaTitle} - Order Online, Delivered to your Door`
              : appConfig.APP_NAME
          }
        />
        <meta property="og:description" content={bundleMetaDescription || appConfig.APP_NAME} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${canonicalHref}${id}`} />

        <link rel="canonical" href={`${canonicalHref}${id}`} />
      </Head>

      {showNavbar && <Header
        hasText
        backPath toPath="/"
        redirectToHome
        hasShadow={isSolid}
        hasWhiteBg
        hideTabs
        showFilter
        showUserInfo={isNewHomePageApplicable}
        showProfile
        showDeliverySpecificMessage={isNewHomePageApplicable}
        showLogin={!isNewHomePageApplicable}
        setTab={setTab}
        help={isNewHomePageApplicable}
      />}

      <ProductContainer
        loading={isLoading}
        updating={isValidating}
        slug={bundleSlug}
        showTag={showTag}
        openInPopup={openInPopup}
        openBrandPage={openBrandPage}
        modalRef={modalRef}
        productDetails={{ ...bundleDetails, is_favourite: bundleDetails?.is_favourite }}
        dismiss={dismiss}
        id={bundleId}
        showNavbar={showNavbar}
      />
    </>
  );
};

const { bundleRedirects: redirects } = grassdoorRedirects;

async function getServerSidePropsFunction({ req, res, query: { slug } }) {
  const cookies = new Cookies(req, res);
  const zipcode = cookies.get(COOKIE_VARIABLES.zipcode) || DEFAULT_ZIPCODE;
  const latitude = cookies.get(COOKIE_VARIABLES.latitude) || DEFAULT_LOCATION.lat;
  const longitude = cookies.get(COOKIE_VARIABLES.longitude) || DEFAULT_LOCATION.lng;
  const placeId = cookies.get(COOKIE_VARIABLES.placeId) || '';
  const token = cookies.get(COOKIE_VARIABLES.token) || '';
  const host = req?.headers?.host || '';

  const index = oldProductSlug.indexOf(slug);
  if (redirects[slug] && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR) {
    res.writeHead(301, { location: `${redirects[slug]}` });
    res.end();
  } else if (process.env.NEXT_PUBLIC_APP === WEBSITES.KIKOKO) {
    const kikokoRedirect = {
      'honeyshot-buzz-bundle': '/bundle/honeyshot-buzz-live-resin-ruthless-og-5-pack'
    };

    if (kikokoRedirect[slug]) {
      res.writeHead(301, { location: `${kikokoRedirect[slug]}` });
      res.end();
    }
  } else if (slug && index !== -1) {
    res.writeHead(301, { location: `/bundle/${newProductSlug[index]}` });
    res.end();
  }

  let authorizationToken = {};
  if (token) {
    authorizationToken = {
      authorization: `Bearer ${token}`
    };
  }

  try {
    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/${zipcode}/bundles/${slug}/`,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, ...authorizationToken }
    });

    const bundleDetails = response?.data?.data?.details || {};
    if (Object.keys(bundleDetails).length === 0 && bundleDetails.constructor === Object) {
      return { props: { error: 404 } };
    }

    return {
      props: {
        id: String(slug),
        bundleDetails,
        host
      }
    };
  } catch (error) {
    res.statusCode = 404;
    return { props: { error: 404 } };
  }
}
export const getServerSideProps = withConfig(getServerSidePropsFunction)

export default pageDisable(BundlePage);
