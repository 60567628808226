import React, { useState, useEffect } from 'react';
import Image from 'next/image';
// import appConfig from '../appConfig';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import { changeImgFormateFilter } from '../helpers/imageOptimization';
import { useConfigData } from '../Context/ConfigProvider';

const LoadImage = React.memo(
  ({ alt, src, className, width, height, thumb = false, onErrorSrc, onClick = false, avoidLazyLoad = false }) => {
    const dimensionRegex = /\d+x\d+/gm;
    const [webpSrc] = useState(changeImgFormateFilter(src, 'webp'));
    const [smallImage, setSmallImage] = useState('');
    const [loadComplete, setLoadComplete] = useState('');
    const [errorImage, setErrorImage] = useState(false);
    const appConfig = useConfigData();
    const widthHeightProps = {};

    if (width && height) {
      widthHeightProps.width = width;
      widthHeightProps.height = height;
    }
    const createSmallImage = () => {
      const test = src?.match(dimensionRegex);
      setSmallImage(
        (!test ? getTransformedImageURLs(src, 'png').srcSmall : src)?.replace(dimensionRegex, thumb ? '15x15' : '30x30')
      );
    };

    useEffect(() => {
      createSmallImage();
    }, []);

    if (!process.browser) {
      return null;
    }

    return (
      <>
        <picture className="d-none">
          <source srcSet={changeImgFormateFilter(src, 'webp')} type="image/webp" />
          <source srcSet={changeImgFormateFilter(src, 'png')} type="image/png" />
          <img src={webpSrc} onLoad={e => setLoadComplete(e.target.currentSrc)} onError={() => setErrorImage(true)} />
        </picture>
        {(smallImage || loadComplete) && !errorImage ? (
          <img
            key={src}
            src={loadComplete || smallImage}
            className={`${className} loader-main-image ${loadComplete ? 'fully-loaded' : 'not-fully-loaded'}`}
            {...widthHeightProps}
            alt={alt}
            onError={e => {
              e.target.src = onErrorSrc || appConfig.PRODUCT_PLACEHOLDER_IMG;
              setLoadComplete(appConfig.PRODUCT_PLACEHOLDER_IMG);
            }}
            loading="lazy"
            onClick={() => (onClick ? onClick() : null)}
          // onLoadingComplete={(imageDimension) => {loadingComplete(imageDimension)}}
          />
        ) : (
          <img alt={alt} className={className} {...widthHeightProps} src={onErrorSrc || appConfig.PRODUCT_PLACEHOLDER_IMG} />
        )}
      </>
    );
  }
);

export default LoadImage;
