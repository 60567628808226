export default {
  "APP_NAME": "OmniGreen",
  "APP_URL": "https://store.omnigreen.ai",
  "TWITTER": "https://twitter.com",
  "FACEBOOK": "https://www.facebook.com",
  "INSTAGRAM": "https://www.instagram.com",
  "LINKEDIN": "https://www.linkedin.com/company",
  "Youtube": "https://www.youtube.com",
  "FAQ_URL": "https://store.omnigreen.ai/faq",
  "TERMS": "https://store.omnigreen.ai/termsandconditions",
  "PRIVACY": "https://store.omnigreen.ai/privacypolicy",
  "HEADER_LOGO_SHORT": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "HEADER_LOGO_COLOR": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo.png",
  "HEADER_LOGO_WHITE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo.png",
  "LOGO": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "LOGO_WHITE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "LOGO_COLOR": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "LIVE_CHAT_IMAGE": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/livechat64d454483915f32afdf7becd3ed78d7b.png",
  "AGE_21": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/age21_grassdoor_21.png",
  "OFFER_PLACEHOLDER": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/specialoffer.png",
  "BANNER_ARRAY": [
    {
      "mobile": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/grassdoorBanner.jpg",
      "desktop": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/grassdoorBanner.jpg"
    }
  ],
  "BANNER": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/grassdoorBanner.jpg",
  "THIRD_PARTY_MEDICAL_CARD": false,
  "FABICON_URL": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "MANIFEST_URL": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/manifest/manifest.json",
  "PRODUCT_PLACEHOLDER_IMG": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/default_product.png",
  "PRIMARY": "#154133",
  "BODY_BACKGROUND_COLOR": "#ffffff",
  "FOOTER_COLOR": "#ffffff",
  "BRAND_GRADIENT_TOP": "#2af499",
  "BRAND_GRADIENT_BOTTOM": "#0ab3e5",
  "HEADLINE_FONT": "Montserrat",
  "HEADLINE": "Los Angeles On Demand Cannabis Delivery",
  "SUBHEAD": "California's safest and most reliable weed delivery.",
  "FIRST_ORDER_TITLE": "Welcome to Flower & Edibles!",
  "FIRST_ORDER_MESSAGE": "Thank your friend for referring you because you're going to save 30% on your first two orders! We hope you have a great first delivery.",
  "SCHEDULE_PAGE_TITLE": "Schedule Shop | Marijuana Delivery in Los Angeles",
  "META_IMAGE": "https://omnigreen-public-data.s3.us-west-2.amazonaws.com/store.omnigreen.ai/web_static_files/website-logo-m.png",
  "META_TITLE": "California's Best Weed Delivery | Los Angeles",
  "META_DESCRIPTION": "Flower & Edibles offers legal, reliable weed delivery to your front door anywhere in Los Angeles; in one hour or less. Cannabis, vape pens, edibles &amp; more.",
  "META_TITLE_DESCRIPTION": "Los Angeles",
  "hideProductWeight": false,
  "hideProductProperties": false,
  "isGrassdoor": false,
  "emailVerificationFlag": true,
  "SEGMENT_KEY": "GzR2cDMu8ub6K31bqHi0vLLdTxAiBrNS",
  "ZENDESK_KEY": "8e7332db-3a8f-4b60-9500-9e74c5964b08",
  "supportContact": "(510)994-9744",
  "supportEmail": "support@flowerandedibles.com",
  "BCC_QR_CODE": {
    "text": "Flower & Edibles (C9-0000128-LIC)",
    "image": "https://static-images.flowerandedibles.com/store.omnigreen.ai/web_static_files/bcc_qr_code.png"
  },
  "exchagePolicy": "<p class='four-rem-pb'>Flower & Edibles will consider exchanging damaged or defective cannabis products if they are reported to <a href='mailto:support@flowerandedibles.com'>support@flowerandedibles.com </a>within 24 hours of purchase. All non-damaged cannabis product sales are final. We do not exchange products when customers do not like the look, feel, taste, smell or any other characteristic of the product. We only consider exchanges when a hardware product, such as a battery, pod or cart are damaged or defective. All exchanges shall be at the discretion of Flowers & Edibles. Refunds are not permitted.</p><br/><br/>",
  "MIN_ORDER_AMT": 0,
  "SALE_TAG_NAME": "SALE",
  "SUPPORT_URL": "https://store.omnigreen.ai",
  "OPTIMIZE_ID": "OPT-K8G25NR",
  "OPTIMIZE_EXPERMENT_ID": "ITFelReyS-24QGepO_YCFA",
  "FRESHDESKWIDGETID": "bed6cfeb-58b4-4030-81a5-8f1db86b7689"
};