import React from 'react';
import dynamic from 'next/dynamic';

import { isNewHomePageApplicable } from '../../constants/feature';

const GrassdoorProductSkeleton = dynamic(() => import('./grassdoor/ProductSkeleton'));
const GrassdoorProductSkeletonNew = dynamic(() => import('./grassdoor/ProductSkeletonNew'));
const GrassdoorProductSkeletonNewSearch = dynamic(() => import('./grassdoor/ProductSkeletonNewSearch'));

const ProductSkeleton = props => {
  const { isSearchPage = false } = props;

  return isSearchPage ? (
    <GrassdoorProductSkeletonNewSearch {...props} />
  ) : isNewHomePageApplicable ? (
    <GrassdoorProductSkeletonNew {...props} />
  ) : (
    <GrassdoorProductSkeleton {...props} />
  );
};

// Wrap the component with React.memo to optimize re-renders
export default React.memo(ProductSkeleton);
