// import appConfig from '../appConfig';

import { miligram, percentage, range, ratio } from '../constants/productAttributeType';
import {
  ALTLIFE,
  BEED,
  BUYPROOFNOW,
  CLOUD11,
  CREAMOFTHECROP,
  DREWMARTIN,
  HELLOAGAIN,
  HUMBOLDTFAMILYFARMS,
  MAISONBLOOM,
  PACIFICSTONE,
  PUREBEAUTY,
  TRYCHEMISTRY,
  VETCBD,
  WUNDER,
  GETOOKA,
  RIGHTRATIO
} from '../constants/website';

export const getProductAttributeValue = data => {

  switch (data.type) {
    case percentage:

      return `${data.value}%`;

    case range:
      return `${data.value.replace(',', '-')}%`;

    case miligram:

      return `${data.value} mg`;

    case ratio:
      return data.value;

    default:
      return `${data.value}`;
  }
};

export const getThcPerDollarAttrbutes = (attributes = [], hideProductProperties) => {
  const currentDomain = process.env.NEXT_PUBLIC_APP;

  const domainWithHiddenPerDollarAttributes = [TRYCHEMISTRY];

  const perDollarAttributes = [];

  if (hideProductProperties || domainWithHiddenPerDollarAttributes.includes(currentDomain)) {
    return perDollarAttributes;
  }

  if (attributes?.length) {
    attributes.forEach(attribute => {
      if (attribute.type !== ratio) {
        switch (attribute.name) {
          case 'THC': {
            if (
              ![
                PACIFICSTONE,
                DREWMARTIN,
                VETCBD,
                CREAMOFTHECROP,
                BUYPROOFNOW,
                HUMBOLDTFAMILYFARMS,
                HELLOAGAIN,
                BEED,
                PUREBEAUTY,
                RIGHTRATIO
              ].includes(currentDomain)
            ) {
              perDollarAttributes.push(attribute);
            }

            break;
          }

          case 'THC PER SERVING': {
            if (![WUNDER, CLOUD11, ALTLIFE, BUYPROOFNOW, MAISONBLOOM, HELLOAGAIN, CREAMOFTHECROP].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }

            break;
          }

          case 'THC PER PACK': {
            if (![RIGHTRATIO].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }
            break;
          }

          case 'CBD': {
            if (
              ![DREWMARTIN, VETCBD, HELLOAGAIN, BUYPROOFNOW, HUMBOLDTFAMILYFARMS, CREAMOFTHECROP, RIGHTRATIO].includes(
                currentDomain
              )
            ) {
              perDollarAttributes.push(attribute);
            }

            break;
          }

          case 'CBD PER PACK': {
            if (![RIGHTRATIO].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }
            break;
          }

          case 'CBD PER SERVING': {
            if (![CLOUD11, MAISONBLOOM, HELLOAGAIN, CREAMOFTHECROP].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }

            break;
          }

          default:
            break;
        }
      }
    });
  }

  return perDollarAttributes;
};

export const getThcPerDollarAttrbutesName = (attributes = [], hideProductProperties) => {
  const currentDomain = process.env.NEXT_PUBLIC_APP;
  const domainWithHiddenPerDollarAttributes = [TRYCHEMISTRY];
  const perDollarAttributes = [];

  if (hideProductProperties || domainWithHiddenPerDollarAttributes.includes(currentDomain)) {
    return perDollarAttributes;
  }

  if (attributes?.length) {
    attributes.forEach(attribute => {
      if (attribute.type !== ratio) {
        switch (attribute) {
          case 'THC': {
            if (
              ![
                PACIFICSTONE,
                DREWMARTIN,
                VETCBD,
                CREAMOFTHECROP,
                BUYPROOFNOW,
                HUMBOLDTFAMILYFARMS,
                HELLOAGAIN,
                BEED,
                PUREBEAUTY
              ].includes(currentDomain)
            ) {
              perDollarAttributes.push(attribute);
            }
            break;
          }
          case 'THC PER SERVING': {
            if (![WUNDER, CLOUD11, ALTLIFE, BUYPROOFNOW, MAISONBLOOM, HELLOAGAIN, CREAMOFTHECROP].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }
            break;
          }
          case 'THC PER PACK': {
            perDollarAttributes.push(attribute);

            break;
          }
          case 'CBD': {
            if (![DREWMARTIN, VETCBD, HELLOAGAIN, BUYPROOFNOW, HUMBOLDTFAMILYFARMS, CREAMOFTHECROP].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }
            break;
          }
          case 'CBD PER PACK': {
            perDollarAttributes.push(attribute);

            break;
          }
          case 'CBD PER SERVING': {
            if (![CLOUD11, MAISONBLOOM, HELLOAGAIN, CREAMOFTHECROP].includes(currentDomain)) {
              perDollarAttributes.push(attribute);
            }
            break;
          }
          default:
            break;
        }
      }
    });
  }
  return perDollarAttributes;
};
export const calculateProductAttributePerDoller = (data, price, weight, unit) => {
  function calculateOnProductUnit(containsPerDoller, productUnit) {
    switch (productUnit) {
      case 'kg':
        return `${(containsPerDoller * 1000000).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 1000000).toFixed(4)}`
          : `${(containsPerDoller * 1000000).toFixed(1)}`;
      case 'g':
      case 'gram':
      case 'grams':
        return `${(containsPerDoller * 1000).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 1000).toFixed(4)}`
          : `${(containsPerDoller * 1000).toFixed(1)}`;
      case 'lb':
        return `${(containsPerDoller * 453592).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 453592).toFixed(4)}`
          : `${(containsPerDoller * 453592).toFixed(1)}`;
      case 'oz':
        return `${(containsPerDoller * 28350).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 28350).toFixed(4)}`
          : `${(containsPerDoller * 28350).toFixed(1)}`;
      case '1/8 oz':
        return `${(containsPerDoller * 3543).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 3543).toFixed(4)}`
          : `${(containsPerDoller * 3543).toFixed(1)}`;
      case '1/2 oz':
        return `${(containsPerDoller * 14174).toFixed(1)}` === '0.0'
          ? `${(containsPerDoller * 14174).toFixed(4)}`
          : `${(containsPerDoller * 14174).toFixed(1)}`;
      case 'EA':
      case 'mg':
      default:
        return `${containsPerDoller.toFixed(1)}` === '0.0'
          ? `${containsPerDoller.toFixed(4)}`
          : `${containsPerDoller.toFixed(1)}`;
    }
  }

  switch (data.type) {
    case percentage: {
      const containsInProductUnit = (data.value * weight) / 100;
      const containsPerDoller = containsInProductUnit / price;

      return `${calculateOnProductUnit(containsPerDoller, unit)} mg`;
    }

    case range: {
      const rangeValue = data.value.split(',');
      const containsInProductUnitA = (rangeValue[0] * weight) / 100;
      const containsInProductUnitB = (rangeValue[1] * weight) / 100;

      return `${calculateOnProductUnit(containsInProductUnitA / price, unit)} mg - ${calculateOnProductUnit(
        containsInProductUnitB / price,
        unit
      )} mg`;
    }

    case miligram: {
      const containsPerDoller = data.value / price;

      return containsPerDoller.toFixed(1) === '0.0' ? `${containsPerDoller.toFixed(4)} mg` : `${containsPerDoller.toFixed(1)} mg`;
    }

    default: {
      const containsPerDoller = data.value / price;

      return `${calculateOnProductUnit(containsPerDoller, unit)} mg`;
    }
  }
};
