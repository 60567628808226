import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';

// import appConfig from '../appConfig';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import { useConfigData } from '../Context/ConfigProvider';

export default function ThreeSixtyImage({ productDetails }) {
  const [isMouseDown, setMouseDown] = useState(false);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [show360, setShow360] = useState(false);
  const [playingState, setPlayingState] = useState('playing');
  const containerRef = useRef(null);
  const clickPosition = useRef(0);
  const imagePosition = useRef(0);
  const interval = useRef();
  const imageArray = productDetails?.images_360.length
    ? productDetails.images_360.map(image => getTransformedImageURLs(image, 'png').srcLarge)
    : [];
  const spinFactor = 0.5; // a full drag will result in 2 rotations
  const imageInterval = (1000 * 12) / imageArray.length;
  const appConfig = useConfigData();
  function mod(n, m) {
    return ((n % m) + m) % m;
  }

  function loadImage(url) {
    return new Promise(resolve => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = resolve;
    });
  }

  const start = useCallback(() => {
    setPlayingState('playing');
    if (interval.current) {
      return;
    }
    interval.current = setInterval(() => {
      setImageIndex(index => mod(index + 1, imageArray.length));
    }, imageInterval);
  }, []);

  function pause() {
    setPlayingState('paused');
    clearInterval(interval.current);
    interval.current = null;
  }

  useEffect(() => {
    async function loadAllImages() {
      const promiseArray = [];
      imageArray.forEach(image => {
        promiseArray.push(loadImage(image));
      });
      await Promise.all(promiseArray);
      setShow360(true);
      start();
    }
    loadAllImages();
    return () => {
      setMouseDown(false);
      pause();
    };
  }, []);

  function getMouseX(e) {
    const rect = containerRef.current.getBoundingClientRect();
    const width = containerRef.current.offsetWidth;
    const mouseX = (e.clientX || e.touches[0].clientX) - rect.left - width / 2;
    return mouseX;
  }

  function onMouseMove(e) {
    e.stopPropagation();
    if (isMouseDown) {
      const mouseX = getMouseX(e);
      const difference = mouseX - clickPosition.current;
      const width = containerRef.current.offsetWidth;

      const index = mod(
        Math.floor(imagePosition.current + difference / (width / spinFactor / imageArray.length)),
        imageArray.length
      );
      if (index != currentImageIndex) {
        setImageIndex(index);
      }
    }
  }

  function onMouseDown(e) {
    e.stopPropagation();
    setMouseDown(true);
    const mouseX = getMouseX(e);

    clickPosition.current = mouseX;
    imagePosition.current = currentImageIndex;
    pause();
  }
  const productAltText =
    productDetails.three_sixty_alt_tag && productDetails.three_sixty_alt_tag !== ''
      ? productDetails.three_sixty_alt_tag
      : productDetails.product_name;

  if (!show360) {
    return (
      <div className="img-container relative three-60-parent">
        <img alt={productAltText} src={appConfig.PRODUCT_PLACEHOLDER_IMG} />
      </div>
    );
  }
  const alt =
    productDetails && productDetails.category_name
      ? `${productDetails.category_name} ${productDetails.meta_title}`
      : appConfig.APP_NAME;

  return (
    <div
      ref={containerRef}
      onMouseDown={onMouseDown}
      onMouseUp={() => setMouseDown(false)}
      onMouseLeave={() => setMouseDown(false)}
      onMouseMove={onMouseMove}
      onTouchStart={onMouseDown}
      onTouchMove={onMouseMove}
      onTouchEnd={e => {
        e.stopPropagation();
        setMouseDown(false);
      }}
      className="img-container relative three-60-parent"
    >
      {/* {imageArray.map((image, index) => (
        <img
          alt={
            productDetails && productDetails.categoryName
              ? `${productDetails.categoryName} ${productDetails.metaTitle}`
              : appConfig.APP_NAME
          }
          draggable="false"
          src={image}
          className="img-fluid"
          style={index != currentImageIndex ? { display: 'none' } : {}}
        />
      ))} */}

      <img alt={productAltText} draggable="false" src={imageArray[currentImageIndex]} className="" />
      <div className="t-btn-holder text-center">
        <button disabled={playingState == 'playing'} className="btn control-btn btn-outline-primary" onClick={start}>
          <i className="icon-play" />
        </button>
        <button disabled={playingState == 'paused'} className="btn control-btn btn-outline-primary" onClick={pause}>
          <i className="icon-pause" />
        </button>
      </div>
    </div>
  );
}
