import { BENTO, FLOWERANDEDIBLES, GANJAGODDESS, GRASSDOOR, SMOKELAND, DEFAULT } from './website';

export const isFeaturedProductApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isWishlistApplicable =
  [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP) && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true';

export const isPopularCategoryApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isBestSellerApplicable = [GRASSDOOR, BENTO, GANJAGODDESS].includes(process.env.NEXT_PUBLIC_APP);

export const isRecommendedProductsByIdApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isSortByDealFilterApplicable = [GRASSDOOR, BENTO].includes(process.env.NEXT_PUBLIC_APP);

export const isAsapBundlesApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isProfieModuleApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isSearchPageApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isRecommendedCartUpsellApplicable = [GRASSDOOR, BENTO, GANJAGODDESS].includes(process.env.NEXT_PUBLIC_APP);

export const isACSApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isNewOrderDetailsApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const isAddToCartRecommendationApplication = [GRASSDOOR, BENTO, GANJAGODDESS].includes(process.env.NEXT_PUBLIC_APP);

export const isDynamicSiteMap = [GRASSDOOR, BENTO, GANJAGODDESS].includes(process.env.NEXT_PUBLIC_APP);

export const isNewHomePageApplicable = [GRASSDOOR, BENTO, GANJAGODDESS, FLOWERANDEDIBLES, DEFAULT].includes(process.env.NEXT_PUBLIC_APP);

export const showRateUsComponents = [GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP);

export const isDealsMerchandisingApplicable = [GRASSDOOR, BENTO, GANJAGODDESS].includes(process.env.NEXT_PUBLIC_APP);

export const isRewardFeatureApplicable = [GRASSDOOR, BENTO, FLOWERANDEDIBLES].includes(process.env.NEXT_PUBLIC_APP);

