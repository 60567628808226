import React, { useContext, useRef, useEffect } from 'react';
import HraderDeliveryAddress from '../DTC/bento/HraderDeliveryAddress';
import { useRouter } from 'next/router';
import appContext from '../../Context/appContext';

const SearchButton = ({ toggleFilterPopup, hideFilter = false }) => {
  // const { dispatch } = useContext(appContext);

  // const searchRef = useRef(null);
  const router = useRouter();
  const redirectTosearch = () => {
    router.push('/search');
  };

  return (
    <div>
      <div className='hidden lg:flex justify-center w-full items-center gap-4'>
        <div id="search-category-wrapper" className={`search-category-wrapper flex-grow xx max-w-[780px]`}>
          <div className={`searchBox`}>
            <div className="new-search-component">
              <div className="input-group searchBar">
                <div className="input-group searchBar">
                  <HraderDeliveryAddress />
                  <div onClick={redirectTosearch} className="mobile-search-box">
                    <p className="w-100 d-flex align-items-center mb-0">Try "Indica" or "Hybrid"</p>
                    <i className="icon-search" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!hideFilter ? <button onClick={() => toggleFilterPopup(true)} className='hidden md:flex items-center bg-main-color text-white h-[58px] rounded-[24px] px-8 text-[16px]'>
          <span className='icon-filter-dark-i mr-2 text-[20px]' />
          Filter
        </button> : null}
      </div>
    </div>
  );
};

export default SearchButton;
