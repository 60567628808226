import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    asapProductCategory: [],
    scheduleProductCategory: [],
    fullCategoryList: []
};
const modifyCategoryData = (categories) => {
    return !categories ? [] : categories.map(category => ({
        ...category,
        category: true,
        name: category.category_name, // Add `name`
        link: `/category/${category.category_slug}` // Add `link`
    }));
};
const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setFullCategoryList(state, action) {
            state.fullCategoryList = modifyCategoryData(action.payload);
        }
    }
});

export const { setFullCategoryList } = sidebarSlice.actions;
export default sidebarSlice.reducer;
