import React from 'react';
import toFixedFloat from '../helpers/toFixedFloat';
import { useConfigData } from '../Context/ConfigProvider';
import { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE } from '../constants/sales';

const BundlePrice = ({
  className,
  currency,
  orignalPrice,
  price,
  quantity = 1,
  hideStrikePrice = false,
  otherAttributes = {},
  concentrated_weight = 0,
  non_concentrated_weight = 0
}) => {
  // Helper function to apply tax to a given price
  const appConfig = useConfigData()
  const calculateTax = appConfig.DISPLAY_TAXED_PRICE
  const applyTax = (basePrice) => {
    let priceWithTax = basePrice;
    const isCannabisProduct = concentrated_weight > 0 || non_concentrated_weight > 0;

    if (calculateTax) {
      if (isCannabisProduct) {
        priceWithTax += priceWithTax * EXCISE_TAX_RATE;
        priceWithTax += priceWithTax * LOCAL_CANNABIS_TAX_RATE;
      }
      priceWithTax += priceWithTax * SALES_TAX_RATE;
    }
    return priceWithTax;
  };

  // Calculate prices with taxes if applicable
  const finalPrice = applyTax(price * quantity);
  const finalOrignalPrice = orignalPrice ? applyTax(orignalPrice * quantity) : finalPrice;

  if (orignalPrice && price !== orignalPrice) {
    return (
      <>
        <span {...otherAttributes} className={`price discount ${className || ''}`}>{`${currency}${toFixedFloat(
          finalPrice
        )}`}</span>

        {!hideStrikePrice ? (
          <small className="strike-text hide-for-maison">
            {`${currency}${toFixedFloat(finalOrignalPrice)}`}
            &nbsp;
          </small>
        ) : null}
      </>
    );
  }

  return (
    <div className={`price ${className || ''}`} {...otherAttributes}>
      {currency}
      {toFixedFloat(finalPrice)}
    </div>
  );
};

export default BundlePrice;
