import React, { createContext, useContext } from 'react';

const ConfigContext = createContext(null);

export const ConfigProvider = ({ initialConfig, children }) => {
    return (
        <ConfigContext.Provider value={initialConfig}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfigData = () => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
};
