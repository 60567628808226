import useSWR, { useSWRInfinite } from 'swr';

import { useContext, useEffect, useRef } from 'react';
import get from 'lodash/get';
import axios from '../utils/ajax';
import appContext from '../Context/appContext';
import { getdriverlocation, getSocket, getOrderETA } from '../helpers/SocketHelper';
import SocketEmitConst from '../constants/socketConst';
import OrderStatus from '../constants/orderStatus';
// import appConfig from '../appConfig';
import { setRollbarWarning } from '../helpers/rollBar';
import walletType from '../constants/walletType';
import { LEDGERGREEN, NEW_CARD } from '../constants/paymentTypes';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useConfigData } from '../Context/ConfigProvider';

const fetcher = (url, base) =>
  axios({ baseURL: base || process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET' }).then(res => res.data);

export const useIdProof = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/customers/upload_eligible` : null, fetcher, {
    revalidateOnFocus: false
  });

  const idProof = get(data, 'data', {});

  return {
    data: idProof,
    isLoading: !error && !data,
    mutate
  };
};

export const useWeedyStatus = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const appConfig = useConfigData();
  const { data, error, mutate } = useSWR(
    appConfig.THIRD_PARTY_MEDICAL_CARD && isLoggedIn ? `/weedy/order/status` : null,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  const reason = get(data, 'reason', '');
  const status = get(data, 'status', '');
  const is_employee = get(data, 'is_employee', '');

  return {
    data: {
      reason,
      status,
      is_employee
    },
    isLoading: !error && !data,
    mutate
  };
};

export const useLoyaltyDiscount = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error } = useSWR(isLoggedIn ? `/customers/discount` : null, fetcher, {
    revalidateOnFocus: false
  });
  const discountPercent = get(data, 'discount', null);

  return {
    data: discountPercent,
    isLoyal: !!discountPercent,
    isLoading: !error && !data
  };
};

export const useReferralEarned = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, mutate } = useSWR(
    isLoggedIn && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' ? `/customers/referral_earned` : null,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  const referral = get(data, 'data', {});

  return {
    data: referral,
    mutate
  };
};

export const useReferralLink = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data } = useSWR(isLoggedIn ? `/customers/generate_token` : null, fetcher);

  const referralLink = get(data, 'data', {});

  return {
    data: referralLink
  };
};

export const useAmbassadorCode = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, mutate } = useSWR(isLoggedIn ? `/customers/ambassador_code` : null, fetcher);
  const referralCodeList = get(data, 'data', {});
  const ambassadorCodeMessage = get(data, 'message', {});
  return {
    data: { referralCodeList, ambassadorCodeMessage },
    mutate
  };
};

export const useMyOrders = ({ offset }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/orders?offset=${offset}&items=10` : null, fetcher, {
    revalidateOnFocus: false
  });

  const orders = get(data, 'data.data', []);
  const count = get(data, 'data.count', 0);
  return {
    data: { orders, count },
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const useNewMyOrders = ({ offset }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/customers/order_history?offset=${offset}&limit=5` : null, fetcher, {
    revalidateOnFocus: false
  });

  const orders = get(data, 'orders', []);
  const ocount = get(data, 'count', orders.length);
  const giftCardRemainingAmount = get(data, 'gift_card_remaining_amount', 0);
  return {
    data: { orders, ocount, giftCardRemainingAmount },
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const useExpiredCredits = ({ offset }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/customers/expired_credits?offset=${offset}&limit=5` : null, fetcher, {
    revalidateOnFocus: false
  });

  const expiredCredits = get(data, 'expiredCredits', []);
  const edcount = get(data, 'count', 0);
  return {
    data: { expiredCredits, edcount },
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const useExpiringCredits = ({ offset }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/customers/expiring_credits?offset=${offset}&items=5` : null, fetcher, {
    revalidateOnFocus: false
  });

  const expiringCredits = get(data, 'expiringCredits', []);
  const ecount = get(data, 'count', 0);
  return {
    data: { expiringCredits, ecount },
    isLoading: !error && !data,
    error,
    mutate
  };
};

function fetchDriverLocation(orderId, callback) {
  getdriverlocation(orderId, (res, err) => {
    if (res) {
      callback(res);
    } else {
      setRollbarWarning('User.js_fetchDriverLocation_error:', err);
      console.error('User.js_fetchDriverLocation_error', err);
    }
  });
}

function fetchEstimatedTime(orderId, callback) {
  getOrderETA(orderId, (res, err) => {
    if (res) {
      callback(res);
    } else {
      setRollbarWarning('User.js_fetchEstimatedTime_error ', err);
      console.error('User.js_fetchEstimatedTime_error ', err);
    }
  });
}

export const useOrderDetails = ({ orderId, fetchDriverDetails }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  const { data, error, mutate, isValidating } = useSWR(isLoggedIn && orderId ? `/orders/${orderId}` : null, fetcher);

  const orderDetail = get(data, 'data', {});
  const orderStatusId = orderDetail.order_status_id;

  const intervalId = useRef();
  const estimatedTimeIntervalId = useRef();
  const socket = useRef();

  function orderAccepted() {
    mutate();
  }

  function orderOnHold() {
    mutate();
  }

  function orderCancelled() {
    mutate();
  }

  function customerSignature() {
    mutate();
  }

  function reconnect() {
    mutate();
  }

  function orderCompleted() {
    mutate();
  }

  function orderFailed() {
    mutate();
  }

  function refreshCustomerPage() {
    mutate();
  }

  function enterPac() {
    mutate();
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevOrderDetail = usePrevious(orderDetail);

  useEffect(() => {
    socket.current = getSocket();
    socket.current.on(SocketEmitConst.ORDER_ACCEPTED, orderAccepted);
    socket.current.on(SocketEmitConst.ORDER_ON_HOLD, orderOnHold);
    socket.current.on(SocketEmitConst.ORDER_CANCELLED, orderCancelled);
    socket.current.on(SocketEmitConst.CUSTOMER_SIGNATURE, customerSignature);
    socket.current.on(SocketEmitConst.RECONNECT, reconnect);
    socket.current.on(SocketEmitConst.ORDER_COMPLETED, orderCompleted);
    socket.current.on(SocketEmitConst.ORDER_FAILED, orderFailed);
    socket.current.on(SocketEmitConst.REFRESH_CUSTOMER_PAGE, refreshCustomerPage);
    socket.current.on(SocketEmitConst.ENTER_PAC, enterPac);

    return () => {
      clearInterval(intervalId.current);
      clearInterval(estimatedTimeIntervalId.current);

      if (socket.current) {
        socket.current.removeListener(SocketEmitConst.ORDER_ACCEPTED, orderAccepted);
        socket.current.removeListener(SocketEmitConst.ORDER_ON_HOLD, orderOnHold);
        socket.current.removeListener(SocketEmitConst.ORDER_CANCELLED, orderCancelled);
        socket.current.removeListener(SocketEmitConst.CUSTOMER_SIGNATURE, customerSignature);
        socket.current.removeListener(SocketEmitConst.RECONNECT, reconnect);
        socket.current.removeListener(SocketEmitConst.ORDER_COMPLETED, orderCompleted);
        socket.current.removeListener(SocketEmitConst.ORDER_FAILED, orderFailed);
        socket.current.removeListener(SocketEmitConst.REFRESH_CUSTOMER_PAGE, refreshCustomerPage);
        socket.current.removeListener(SocketEmitConst.ENTER_PAC, enterPac);
      }
    };
  }, []);

  useEffect(() => {
    if (!fetchDriverDetails) {
      return;
    }

    if (orderStatusId == OrderStatus.PLACED || orderStatusId == OrderStatus.ASSIGNED) {
      clearInterval(estimatedTimeIntervalId.current);
      fetchEstimatedTime(orderId, () => {
        mutate();
      });

      estimatedTimeIntervalId.current = setInterval(() => {
        fetchEstimatedTime(orderId, () => {
          mutate();
        });
      }, 60000);
    } else if (orderStatusId == OrderStatus.DISPATCHED || orderStatusId == OrderStatus.DRIVER_REACHED) {
      clearInterval(intervalId.current);

      fetchDriverLocation(orderId, () => {
        mutate();
      });

      intervalId.current = setInterval(() => {
        fetchDriverLocation(orderId, () => {
          mutate();
        });
      }, 60000);
    } else {
      clearInterval(intervalId.current);
      clearInterval(estimatedTimeIntervalId.current);
    }
  }, [orderStatusId]);

  return {
    data: orderDetail || prevOrderDetail,
    mutate,
    isLoading: !error && !data,
    isValidating,
    error
  };
};

export const useWalletAmount = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error } = useSWR(isLoggedIn ? `/payments` : null, fetcher, {
    revalidateOnFocus: false
  });

  const walletAmount = get(data, 'wallet_amount', 0);
  const customerId = get(data, 'customer_id', null);

  return {
    data: { customerId, walletAmount },
    isLoading: !error && !data,
    error
  };
};

export const useAddressHistory = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/address` : null, fetcher);

  const addressHistory = get(data, 'data', []);

  return {
    data: addressHistory,
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const useFullAddressHistory = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/address/all` : null, fetcher);

  const addressHistory = get(data, 'data', []);

  return {
    data: addressHistory,
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const useCustomerPrefetch = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error } = useSWR(isLoggedIn ? `/customers/prefetch` : null, fetcher);

  const customerData = get(data, 'data', {});

  return {
    data: customerData,
    isLoading: !error && !data,
    error
  };
};

export const useCardList = ({ payment = false, revalidate = false } = {},) => {
  // const {
  //   state: {
  //     user: { isLoggedIn },
  //     cart: {
  //       customerData: {
  //         paymentType,
  //         cardData
  //       },
  //       ledgerGreenCardData
  //     }
  //   }
  // } = useContext(appContext);

  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const {
    customerData: {
      paymentType,
      cardData
    } = {},
    ledgerGreenCardData
  } = useSelector(state => state.cart) || {}

  const type = payment == LEDGERGREEN || paymentType == LEDGERGREEN ? `?type=${LEDGERGREEN}` : '';
  const { data, error, mutate, isValidating } = useSWR(
    isLoggedIn ? `/get_cards${type}` : null,
    () => fetcher(isLoggedIn ? `/get_cards${type}` : null, process.env.NEXT_PUBLIC_LAMBDA_URL),
    {
      revalidateOnMount: revalidate,
      revalidateOnFocus: revalidate
    }
  );

  const cardList = get(data, 'data', []);
  const allowCardSave = data?.allow_card_save;
  let checkIfNewCardExist = cardList?.filter(item => item.user_card_id == NEW_CARD);
  if (!checkIfNewCardExist.length && (cardData?.newCardChecked || paymentType == LEDGERGREEN && ledgerGreenCardData?.newCardChecked)) {

    allowCardSave !== undefined && cardList.push({
      card_number: paymentType == LEDGERGREEN ? ledgerGreenCardData?.cardNumber?.substr(-4) : cardData?.cardNumber?.substr(-4),
      is_default: cardList?.length ? 0 : 1,
      user_card_id: NEW_CARD
    })
  }
  return {
    data: cardList,
    allowCardSave,
    isLoading: !error && !data,
    error,
    modifyCards: !data?.data,
    isValidating,
    mutate
  };
};

export const useStrongholdList = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/payments` : null, fetcher);

  const shPayments = get(data, 'sh_payments', []);
  const kindTapPayments = get(data, 'kindtapAccount', []);

  return {
    data: shPayments,
    kindTapPayments: kindTapPayments,
    isLoading: !error && !data,
    error,
    mutate
  };
};

// export const useMyWalletTransactions = ({ offset = 0, limit = 10, type }) => {

//   export const useReferralGiftcard = () => {
//   const {
//     state: {
//       user: { isLoggedIn }
//     }
//   } = useContext(appContext);

//   const getUrl = () => {
//     let url = `/customers/credits?offset=${offset}&limit=${limit}`;
//     if (type) {
//       url += type === walletType.giftCard ? `&gift_card=1` : `&gift_card=0`;
//     }
//     return url;
//   };

//   const { data, error, mutate } = useSWR(isLoggedIn ? getUrl() : null, fetcher, {
//     revalidateOnFocus: false
//   });

//   const transactions = get(data, 'credits', []);
//   const currency = get(data, 'data', '$');
//   return {
//     data: { transactions, currency },
//   const limit = 7;
//   const getKey = (pageIndex, previousPageData) => {
//     if (!isLoggedIn) return null;
//     if (previousPageData && !previousPageData?.data?.giftCardData.length) return null; // reached the end
//     return `/referral/giftcard?offset=${pageIndex * limit}&limit=${limit}`; // swr
//   };

//   const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(getKey, fetcher);
//   const recipientList = [];
//   const recipientDataByLink = get(data?.[0]?.data, 'referralLinkData', {});
//   (data || []).forEach(ele => {
//     const recipients = get(ele, 'data.giftCardData', []);
//     recipientList.push(...recipients);
//   });
//   const count = get(data?.[0]?.data, 'totalRefereeCount', 0);
//   const noOfRecipientsByLink = recipientDataByLink?.referredCount || 0;
//   const totalCreditByLink = recipientDataByLink?.creditBalance || 0;
//   return {
//     data: recipientList,
//     noOfRecipientsByLink,
//     totalCreditByLink,
//     isLoading: !error && !data,
//     error,
//     count: count == null || count == undefined || count == '' ? 0 : count,
//     mutate,
//     size,
//     setSize
//   }
// }

// export const useKindTapList = () => {
//   const {
//     state: {
//       user: { isLoggedIn }
//     }
//   } = useContext(appContext);
//   const { data, error, mutate } = useSWR(isLoggedIn ? `/kindtap/accounts` : null, fetcher);
//   const shPayments = get(data, 'accounts', []);

//   return {
//     data: shPayments,
//     isLoading: !error && !data,
//     error,
//     mutate
//   };
// };

export const useReferralGiftcard = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const router = useRouter();
  const limit = 7;
  const getKey = (pageIndex, previousPageData) => {
    if (!isLoggedIn) return null;
    if (router.pathname !== '/referral' && router.pathname !== '/profile/referrals') return null;
    if (previousPageData && !previousPageData?.data?.giftCardData.length) return null; // reached the end
    return `/referral/giftcard?offset=${pageIndex * limit}&limit=${limit}`; // swr
  };

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(getKey, fetcher);
  const recipientList = [];
  const recipientDataByLink = get(data?.[0]?.data, 'referralLinkData', {});
  ((Array.isArray(data) && data) || []).forEach(ele => {
    const recipients = get(ele, 'data.giftCardData', []);
    recipientList.push(...recipients);
  });
  const count = get(data?.[0]?.data, 'totalRefereeCount', 0);
  const noOfRecipientsByLink = recipientDataByLink?.referredCount || 0;
  const totalCreditByLink = recipientDataByLink?.creditBalance || 0;

  return {
    data: recipientList,
    noOfRecipientsByLink,
    totalCreditByLink,
    isLoading: !error && !data,
    error,
    count: count == null || count == undefined || count == '' ? 0 : count,
    mutate,
    size,
    setSize
  };
};

export const useKindTapList = () => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/kindtap/accounts` : null, fetcher);
  const shPayments = get(data, 'accounts', []);

  return {
    data: shPayments,
    isLoading: !error && !data,
    error,
    mutate
  };
};
export const useMyWalletTransactions = ({ offset = 0, limit = 10, type }) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  const getUrl = () => {
    let url = `/customers/credits?offset=${offset}&limit=${limit}`;
    if (type) {
      url += type === walletType.giftCard ? `&gift_card=1` : `&gift_card=0`;
    }
    return url;
  };

  const { data, error, mutate } = useSWR(isLoggedIn ? getUrl() : null, fetcher, {
    revalidateOnFocus: false
  });

  const transactions = get(data, 'credits', []);
  const currency = get(data, 'currency', '$');
  const gift_amount = get(data, 'gift_amount', 0);
  const credit_amount = get(data, 'credit_amount', 0);
  return {
    data: { transactions, currency, gift_amount, credit_amount },
    isLoading: !error && !data,
    error,
    mutate
  };
};

export const getBringItAgainOrders = ({ deliveryType = null } = {}) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { data, error, mutate } = useSWR(isLoggedIn ? `/orders/bring_it_again${deliveryType ? '?deliveryType=' + deliveryType : ''}` : null, fetcher);
  const orderData = get(data, 'data', []);

  return {
    data: orderData,
    isLoading: !error && !data,
    error,
    mutate
  };
};